import zxcvbn from 'zxcvbn';

export default (_, inject) => {
  const uiHelpers = {
    /**
     * Checks the strength of a password and returns an array with the color, strength, and message.
     *
     * @param {string} password - The password to check.
     * @return {Array} An array with the color, strength, and message.
     */
    checkPasswordStrength(password) {
      const colors = ['red', 'customTwo', 'orange', 'yellow', 'success'];
      const strengths = [0, 25, 50, 75, 100];
      let message;

      if (password !== null && password !== undefined) {
        const { score } = zxcvbn(password);
        switch (score) {
          case 0:
            message =
              'Your password is very weak, please choose a stronger one.';
            break;
          case 1:
            message =
              'Your password is weak, consider using more characters and variety.';
            break;
          case 2:
            message =
              'Your password is fair, but could be improved with more unique characters.';
            break;
          case 3:
            message =
              'Your password is good, but there’s still room for improvement.';
            break;
          case 4:
            message = 'Your password is very strong.';
            break;
          default:
            message = 'Unable to evaluate password strength.';
        }

        return [colors[score], strengths[score], message];
      }
      return ['red', 0, 'Password must be at least 8 characters.'];
    },

    /**
     * Checks if the user is logged in and shows a toast message if not.
     *
     * @param {Object} context - The context object from Nuxt.
     * @param {String} message - Optional custom message to show if not logged in.
     * @return {Boolean} True if logged in, false otherwise.
     */
    requireLogin(context, message = 'Please login to continue!') {
      if (!context.$auth.loggedIn) {
        context.$toast.error(message);
        return false;
      }
      return true;
    },

    /**
     * A function to format numbers based on certain conditions.
     *
     * @param {number} number - The number to be formatted.
     * @return {string} The formatted number as a string.
     */
    formatNumber: (number) => {
      if (number < 1000) {
        return number.toString();
      }
      if (number < 1000000) {
        return `${(number / 1000).toFixed(1)}k`;
      }
      if (number < 1000000000) {
        return `${(number / 1000000).toFixed(1)}M`;
      }
      return `${(number / 1000000000).toFixed(1)}B`;
    },

    /**
     * Checks if a given token is valid.
     *
     * @param {string} token - The token to be validated.
     * @return {boolean} Returns true if the token is valid, false otherwise.
     */
    isValidToken(token) {
      return (
        token.length % 8 === 0 &&
        /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(
          token
        )
      );
    },

    /**
     * Returns the initials of a full name.
     *
     * @param {string} fullName - The full name to extract initials from.
     * @return {string} The initials of the full name, or an empty string if fullName is falsy.
     */
    initials: (fullName) => {
      if (fullName) {
        let initials = decodeURI(fullName).match(/\b\w/g) || [];
        initials = (
          (initials.shift() || '') + (initials.pop() || '')
        ).toUpperCase();
        return initials;
      }
      return '';
    },

    /**
     * Formats a raw date into a localized date string.
     *
     * @param {any} rawDate - The raw date to format.
     * @return {string} The formatted date string.
     */
    formatDate(rawDate) {
      return new Date(rawDate).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
    },
  };

  inject('uiHelpers', uiHelpers);
};
