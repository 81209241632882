export const MrAlertBox = () => import('../../node_modules/@motionroutes/ui-lib/components/AlertBox.vue' /* webpackChunkName: "components/mr-alert-box" */).then(c => wrapFunctional(c.default || c))
export const MrCardEditor = () => import('../../node_modules/@motionroutes/ui-lib/components/CardEditor.vue' /* webpackChunkName: "components/mr-card-editor" */).then(c => wrapFunctional(c.default || c))
export const MrEmailInput = () => import('../../node_modules/@motionroutes/ui-lib/components/EmailInput.vue' /* webpackChunkName: "components/mr-email-input" */).then(c => wrapFunctional(c.default || c))
export const MrOverFlowBtn = () => import('../../node_modules/@motionroutes/ui-lib/components/OverFlowBtn.vue' /* webpackChunkName: "components/mr-over-flow-btn" */).then(c => wrapFunctional(c.default || c))
export const MrProductCard = () => import('../../node_modules/@motionroutes/ui-lib/components/ProductCard.vue' /* webpackChunkName: "components/mr-product-card" */).then(c => wrapFunctional(c.default || c))
export const MrProgressBar = () => import('../../node_modules/@motionroutes/ui-lib/components/ProgressBar.vue' /* webpackChunkName: "components/mr-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const MrStatCard = () => import('../../node_modules/@motionroutes/ui-lib/components/StatCard.vue' /* webpackChunkName: "components/mr-stat-card" */).then(c => wrapFunctional(c.default || c))
export const MrSubmitBtn = () => import('../../node_modules/@motionroutes/ui-lib/components/SubmitBtn.vue' /* webpackChunkName: "components/mr-submit-btn" */).then(c => wrapFunctional(c.default || c))
export const MrTextInput = () => import('../../node_modules/@motionroutes/ui-lib/components/TextInput.vue' /* webpackChunkName: "components/mr-text-input" */).then(c => wrapFunctional(c.default || c))
export const MrVideoPlayer = () => import('../../node_modules/@motionroutes/ui-lib/components/VideoPlayer.vue' /* webpackChunkName: "components/mr-video-player" */).then(c => wrapFunctional(c.default || c))
export const CardAdditionalFeaturesCard = () => import('../../components/UI/Card/AdditionalFeaturesCard.vue' /* webpackChunkName: "components/card-additional-features-card" */).then(c => wrapFunctional(c.default || c))
export const CardAuthor = () => import('../../components/UI/Card/Author.vue' /* webpackChunkName: "components/card-author" */).then(c => wrapFunctional(c.default || c))
export const CardBlogPost = () => import('../../components/UI/Card/BlogPost.vue' /* webpackChunkName: "components/card-blog-post" */).then(c => wrapFunctional(c.default || c))
export const CardFeaturesBreakdown = () => import('../../components/UI/Card/FeaturesBreakdown.vue' /* webpackChunkName: "components/card-features-breakdown" */).then(c => wrapFunctional(c.default || c))
export const CardPricing = () => import('../../components/UI/Card/Pricing.vue' /* webpackChunkName: "components/card-pricing" */).then(c => wrapFunctional(c.default || c))
export const CardPricingCard = () => import('../../components/UI/Card/PricingCard.vue' /* webpackChunkName: "components/card-pricing-card" */).then(c => wrapFunctional(c.default || c))
export const CardRatings = () => import('../../components/UI/Card/Ratings.vue' /* webpackChunkName: "components/card-ratings" */).then(c => wrapFunctional(c.default || c))
export const CardReview = () => import('../../components/UI/Card/Review.vue' /* webpackChunkName: "components/card-review" */).then(c => wrapFunctional(c.default || c))
export const CardStep = () => import('../../components/UI/Card/Step.vue' /* webpackChunkName: "components/card-step" */).then(c => wrapFunctional(c.default || c))
export const CardStickyHeaderCard = () => import('../../components/UI/Card/StickyHeaderCard.vue' /* webpackChunkName: "components/card-sticky-header-card" */).then(c => wrapFunctional(c.default || c))
export const CardSuccessTransactionCard = () => import('../../components/UI/Card/SuccessTransactionCard.vue' /* webpackChunkName: "components/card-success-transaction-card" */).then(c => wrapFunctional(c.default || c))
export const HyperLinkForgetPassword = () => import('../../components/UI/HyperLink/ForgetPassword.vue' /* webpackChunkName: "components/hyper-link-forget-password" */).then(c => wrapFunctional(c.default || c))
export const HyperLinkHaveDontHaveAccount = () => import('../../components/UI/HyperLink/HaveDontHaveAccount.vue' /* webpackChunkName: "components/hyper-link-have-dont-have-account" */).then(c => wrapFunctional(c.default || c))
export const HyperLinkRecaptchaDisclaimer = () => import('../../components/UI/HyperLink/RecaptchaDisclaimer.vue' /* webpackChunkName: "components/hyper-link-recaptcha-disclaimer" */).then(c => wrapFunctional(c.default || c))
export const HyperLinkSignupConsent = () => import('../../components/UI/HyperLink/SignupConsent.vue' /* webpackChunkName: "components/hyper-link-signup-consent" */).then(c => wrapFunctional(c.default || c))
export const HyperLinkSignupOrLogin = () => import('../../components/UI/HyperLink/SignupOrLogin.vue' /* webpackChunkName: "components/hyper-link-signup-or-login" */).then(c => wrapFunctional(c.default || c))
export const HyperLinkSwitchToAuthorConsent = () => import('../../components/UI/HyperLink/SwitchToAuthorConsent.vue' /* webpackChunkName: "components/hyper-link-switch-to-author-consent" */).then(c => wrapFunctional(c.default || c))
export const FormAffiliateSignupForm = () => import('../../components/UI/Form/AffiliateSignupForm.vue' /* webpackChunkName: "components/form-affiliate-signup-form" */).then(c => wrapFunctional(c.default || c))
export const FormAutoComplete = () => import('../../components/UI/Form/AutoComplete.vue' /* webpackChunkName: "components/form-auto-complete" */).then(c => wrapFunctional(c.default || c))
export const FormEmailInput = () => import('../../components/UI/Form/EmailInput.vue' /* webpackChunkName: "components/form-email-input" */).then(c => wrapFunctional(c.default || c))
export const FormFileInput = () => import('../../components/UI/Form/FileInput.vue' /* webpackChunkName: "components/form-file-input" */).then(c => wrapFunctional(c.default || c))
export const FormProductFilter = () => import('../../components/UI/Form/ProductFilter.vue' /* webpackChunkName: "components/form-product-filter" */).then(c => wrapFunctional(c.default || c))
export const FormSearchBar = () => import('../../components/UI/Form/SearchBar.vue' /* webpackChunkName: "components/form-search-bar" */).then(c => wrapFunctional(c.default || c))
export const FormSignupForm = () => import('../../components/UI/Form/SignupForm.vue' /* webpackChunkName: "components/form-signup-form" */).then(c => wrapFunctional(c.default || c))
export const FormStartSellingConsent = () => import('../../components/UI/Form/StartSellingConsent.vue' /* webpackChunkName: "components/form-start-selling-consent" */).then(c => wrapFunctional(c.default || c))
export const FormSwitchInput = () => import('../../components/UI/Form/SwitchInput.vue' /* webpackChunkName: "components/form-switch-input" */).then(c => wrapFunctional(c.default || c))
export const FormTextAreaInput = () => import('../../components/UI/Form/TextAreaInput.vue' /* webpackChunkName: "components/form-text-area-input" */).then(c => wrapFunctional(c.default || c))
export const TypographyH1Title = () => import('../../components/UI/Typography/H1Title.vue' /* webpackChunkName: "components/typography-h1-title" */).then(c => wrapFunctional(c.default || c))
export const TypographyH2Title = () => import('../../components/UI/Typography/H2Title.vue' /* webpackChunkName: "components/typography-h2-title" */).then(c => wrapFunctional(c.default || c))
export const PageAffiliateView = () => import('../../components/PageView/AffiliateView.vue' /* webpackChunkName: "components/page-affiliate-view" */).then(c => wrapFunctional(c.default || c))
export const PageAuthorView = () => import('../../components/PageView/AuthorView.vue' /* webpackChunkName: "components/page-author-view" */).then(c => wrapFunctional(c.default || c))
export const PageAuthorsView = () => import('../../components/PageView/AuthorsView.vue' /* webpackChunkName: "components/page-authors-view" */).then(c => wrapFunctional(c.default || c))
export const PageBlogPostView = () => import('../../components/PageView/BlogPostView.vue' /* webpackChunkName: "components/page-blog-post-view" */).then(c => wrapFunctional(c.default || c))
export const PageBlogView = () => import('../../components/PageView/BlogView.vue' /* webpackChunkName: "components/page-blog-view" */).then(c => wrapFunctional(c.default || c))
export const PageCancelTransactionView = () => import('../../components/PageView/CancelTransactionView.vue' /* webpackChunkName: "components/page-cancel-transaction-view" */).then(c => wrapFunctional(c.default || c))
export const PageCheckoutView = () => import('../../components/PageView/CheckoutView.vue' /* webpackChunkName: "components/page-checkout-view" */).then(c => wrapFunctional(c.default || c))
export const PageConfirmEmailView = () => import('../../components/PageView/ConfirmEmailView.vue' /* webpackChunkName: "components/page-confirm-email-view" */).then(c => wrapFunctional(c.default || c))
export const PageContactView = () => import('../../components/PageView/ContactView.vue' /* webpackChunkName: "components/page-contact-view" */).then(c => wrapFunctional(c.default || c))
export const PageEmailVerificationView = () => import('../../components/PageView/EmailVerificationView.vue' /* webpackChunkName: "components/page-email-verification-view" */).then(c => wrapFunctional(c.default || c))
export const PageFAQView = () => import('../../components/PageView/FAQView.vue' /* webpackChunkName: "components/page-f-a-q-view" */).then(c => wrapFunctional(c.default || c))
export const PageGettingStartedView = () => import('../../components/PageView/GettingStartedView.vue' /* webpackChunkName: "components/page-getting-started-view" */).then(c => wrapFunctional(c.default || c))
export const PageHomeView = () => import('../../components/PageView/HomeView.vue' /* webpackChunkName: "components/page-home-view" */).then(c => wrapFunctional(c.default || c))
export const PageLoginView = () => import('../../components/PageView/LoginView.vue' /* webpackChunkName: "components/page-login-view" */).then(c => wrapFunctional(c.default || c))
export const PagePasswordResetCheckView = () => import('../../components/PageView/PasswordResetCheckView.vue' /* webpackChunkName: "components/page-password-reset-check-view" */).then(c => wrapFunctional(c.default || c))
export const PagePasswordResetView = () => import('../../components/PageView/PasswordResetView.vue' /* webpackChunkName: "components/page-password-reset-view" */).then(c => wrapFunctional(c.default || c))
export const PagePrivacyPolicyView = () => import('../../components/PageView/PrivacyPolicyView.vue' /* webpackChunkName: "components/page-privacy-policy-view" */).then(c => wrapFunctional(c.default || c))
export const PageProductReadyView = () => import('../../components/PageView/ProductReadyView.vue' /* webpackChunkName: "components/page-product-ready-view" */).then(c => wrapFunctional(c.default || c))
export const PageProductView = () => import('../../components/PageView/ProductView.vue' /* webpackChunkName: "components/page-product-view" */).then(c => wrapFunctional(c.default || c))
export const PageRefundPolicyView = () => import('../../components/PageView/RefundPolicyView.vue' /* webpackChunkName: "components/page-refund-policy-view" */).then(c => wrapFunctional(c.default || c))
export const PageReleaseNotesView = () => import('../../components/PageView/ReleaseNotesView.vue' /* webpackChunkName: "components/page-release-notes-view" */).then(c => wrapFunctional(c.default || c))
export const PageSignupView = () => import('../../components/PageView/SignupView.vue' /* webpackChunkName: "components/page-signup-view" */).then(c => wrapFunctional(c.default || c))
export const PageStartSellingView = () => import('../../components/PageView/StartSellingView.vue' /* webpackChunkName: "components/page-start-selling-view" */).then(c => wrapFunctional(c.default || c))
export const PageSuccessTransactionView = () => import('../../components/PageView/SuccessTransactionView.vue' /* webpackChunkName: "components/page-success-transaction-view" */).then(c => wrapFunctional(c.default || c))
export const PageTermsOfServiceView = () => import('../../components/PageView/TermsOfServiceView.vue' /* webpackChunkName: "components/page-terms-of-service-view" */).then(c => wrapFunctional(c.default || c))
export const PageThankYouView = () => import('../../components/PageView/ThankYouView.vue' /* webpackChunkName: "components/page-thank-you-view" */).then(c => wrapFunctional(c.default || c))
export const PageUserSettingsView = () => import('../../components/PageView/UserSettingsView.vue' /* webpackChunkName: "components/page-user-settings-view" */).then(c => wrapFunctional(c.default || c))
export const UiAnnouncementBanner = () => import('../../components/UI/AnnouncementBanner.vue' /* webpackChunkName: "components/ui-announcement-banner" */).then(c => wrapFunctional(c.default || c))
export const UiAvatarCircle = () => import('../../components/UI/AvatarCircle.vue' /* webpackChunkName: "components/ui-avatar-circle" */).then(c => wrapFunctional(c.default || c))
export const UiBlogMetaSection = () => import('../../components/UI/BlogMetaSection.vue' /* webpackChunkName: "components/ui-blog-meta-section" */).then(c => wrapFunctional(c.default || c))
export const UiBlogSocialShareSection = () => import('../../components/UI/BlogSocialShareSection.vue' /* webpackChunkName: "components/ui-blog-social-share-section" */).then(c => wrapFunctional(c.default || c))
export const UiCheckBox = () => import('../../components/UI/CheckBox.vue' /* webpackChunkName: "components/ui-check-box" */).then(c => wrapFunctional(c.default || c))
export const UiCountDownTimer = () => import('../../components/UI/CountDownTimer.vue' /* webpackChunkName: "components/ui-count-down-timer" */).then(c => wrapFunctional(c.default || c))
export const UiExploreBlogs = () => import('../../components/UI/ExploreBlogs.vue' /* webpackChunkName: "components/ui-explore-blogs" */).then(c => wrapFunctional(c.default || c))
export const UiExploreProducts = () => import('../../components/UI/ExploreProducts.vue' /* webpackChunkName: "components/ui-explore-products" */).then(c => wrapFunctional(c.default || c))
export const UiExploreSteps = () => import('../../components/UI/ExploreSteps.vue' /* webpackChunkName: "components/ui-explore-steps" */).then(c => wrapFunctional(c.default || c))
export const UiFAQSection = () => import('../../components/UI/FAQSection.vue' /* webpackChunkName: "components/ui-f-a-q-section" */).then(c => wrapFunctional(c.default || c))
export const UiFeatureCard = () => import('../../components/UI/FeatureCard.vue' /* webpackChunkName: "components/ui-feature-card" */).then(c => wrapFunctional(c.default || c))
export const UiFeaturedProduct = () => import('../../components/UI/FeaturedProduct.vue' /* webpackChunkName: "components/ui-featured-product" */).then(c => wrapFunctional(c.default || c))
export const UiLoadingBar = () => import('../../components/UI/LoadingBar.vue' /* webpackChunkName: "components/ui-loading-bar" */).then(c => wrapFunctional(c.default || c))
export const UiMobilePageStepper = () => import('../../components/UI/MobilePageStepper.vue' /* webpackChunkName: "components/ui-mobile-page-stepper" */).then(c => wrapFunctional(c.default || c))
export const UiOverviewFeature = () => import('../../components/UI/OverviewFeature.vue' /* webpackChunkName: "components/ui-overview-feature" */).then(c => wrapFunctional(c.default || c))
export const UiPageStepper = () => import('../../components/UI/PageStepper.vue' /* webpackChunkName: "components/ui-page-stepper" */).then(c => wrapFunctional(c.default || c))
export const UiPersistantAlertBox = () => import('../../components/UI/PersistantAlertBox.vue' /* webpackChunkName: "components/ui-persistant-alert-box" */).then(c => wrapFunctional(c.default || c))
export const UiRatingProgress = () => import('../../components/UI/RatingProgress.vue' /* webpackChunkName: "components/ui-rating-progress" */).then(c => wrapFunctional(c.default || c))
export const UiRecommendedProducts = () => import('../../components/UI/RecommendedProducts.vue' /* webpackChunkName: "components/ui-recommended-products" */).then(c => wrapFunctional(c.default || c))
export const UiSysReqSection = () => import('../../components/UI/SysReqSection.vue' /* webpackChunkName: "components/ui-sys-req-section" */).then(c => wrapFunctional(c.default || c))
export const UiUICounter = () => import('../../components/UI/UICounter.vue' /* webpackChunkName: "components/ui-u-i-counter" */).then(c => wrapFunctional(c.default || c))
export const UiVersionCard = () => import('../../components/UI/VersionCard.vue' /* webpackChunkName: "components/ui-version-card" */).then(c => wrapFunctional(c.default || c))
export const UserAppCard = () => import('../../components/SettingsUI/AppCard.vue' /* webpackChunkName: "components/user-app-card" */).then(c => wrapFunctional(c.default || c))
export const UserAppsSettingsView = () => import('../../components/SettingsUI/AppsSettingsView.vue' /* webpackChunkName: "components/user-apps-settings-view" */).then(c => wrapFunctional(c.default || c))
export const UserPasswordSettingsView = () => import('../../components/SettingsUI/PasswordSettingsView.vue' /* webpackChunkName: "components/user-password-settings-view" */).then(c => wrapFunctional(c.default || c))
export const UserProfileSettingsView = () => import('../../components/SettingsUI/ProfileSettingsView.vue' /* webpackChunkName: "components/user-profile-settings-view" */).then(c => wrapFunctional(c.default || c))
export const FooterSection = () => import('../../components/FooterSection.vue' /* webpackChunkName: "components/footer-section" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const LearnMorePageVideoTutorial = () => import('../../components/LearnMorePage/VideoTutorial.vue' /* webpackChunkName: "components/learn-more-page-video-tutorial" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
