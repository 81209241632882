/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { handleAction } from './helpers';

export const state = () => ({
  selectedPlan: null, //! Deprecated
  selectedProduct: null, //! Deprecated
  isCookieAccepted: false, //! Deprecated
  isLoggedIn: false, //! Deprecated
  jwt: null, //! Deprecated
  email: null,
  name: null,
  isEmailVerified: false,
  id: null,
  userName: null,
  userSubscription: null,
  userGroup: null,
});

export const mutations = {
  SET_COOKIE(state, response) {
    state.isCookieAccepted = response;
  },
  SET_PLAN(state, plan) {
    state.selectedPlan = plan;
  },
  SET_PRODUCT(state, product) {
    state.selectedProduct = product;
  },
  SET_SUBSCRIPTION(state, subscription) {
    state.userSubscription = subscription;
  },
  SET_USER_GROUP(state, userGroup) {
    state.userGroup = userGroup;
  },
  SET_USER_DATA(
    state,
    { isLoggedIn, jwt, email, name, isEmailVerified, id, userName }
  ) {
    state.isLoggedIn = isLoggedIn;
    state.jwt = jwt;
    state.email = email;
    state.name = name;
    state.isEmailVerified = isEmailVerified;
    state.id = id;
    state.userName = userName;
  },
};

export const getters = {
  getUserGroup: (state) => state.userGroup,
};

export const actions = {
  setPlan({ commit }, plan) {
    commit('SET_PLAN', plan);
  },
  setUserGroup({ commit }, userGroup) {
    commit('SET_USER_GROUP', userGroup);
  },
  setProduct({ commit }, product) {
    commit('SET_PRODUCT', product);
    return [true, undefined];
  },
  setSubscription({ commit }, subscription) {
    commit('SET_SUBSCRIPTION', subscription);
  },
  async requestNewEmailVerificationToken(_, { email }) {
    return handleAction(
      () => this.$api.requestNewEmailVerificationToken(email),
      (data) => data
    );
  },
  async requestEmailVerificationToken() {
    return handleAction(
      () => this.$api.requestEmailVerificationToken(),
      (data) => data
    );
  },
  async verifyEmailVerificationToken(_, { token, uuid }) {
    return handleAction(
      () => this.$api.verifyEmailVerificationToken(token, uuid),
      (data) => data
    );
  },
  async verifyDownloadToken(_, { downloadToken, uuid }) {
    return handleAction(
      () => this.$api.verifyDownloadToken(downloadToken, uuid),
      (data) => data
    );
  },
  async requestPasswordRecoverToken(_, { email }) {
    return handleAction(
      () => this.$api.requestPasswordRecoverToken(email),
      (data) => data
    );
  },
  async requestApplicationDownloadLink(_, { subscriptionUUID }) {
    return handleAction(
      () => this.$api.requestApplicationDownloadToken(subscriptionUUID),
      (data) => data
    );
  },
  async requestStripeCustomerPortal(_, { stripeCustomerId }) {
    return handleAction(
      () => this.$api.requestStripeCustomerPortal(stripeCustomerId),
      (data) => data
    );
  },
  async sendContactMessage(
    _,
    { selectedTopic, selectedApp, name, email, message, issue }
  ) {
    return handleAction(
      () =>
        this.$api.sendContactMessage(
          selectedTopic,
          selectedApp,
          name,
          email,
          issue,
          message // TODO: remove issue from backend
        ),
      (data) => data
    );
  },
  async verifyNewPassword(_, { uuid, password, token }) {
    return handleAction(
      () => this.$api.verifyNewPassword(uuid, password, token),
      (data) => data
    );
  },
  async subscribeToProduct(_, { uuid, affiliateUserName }) {
    // TODO: must be moved to product store
    return handleAction(
      () => this.$api.subscribeUserToProduct(uuid, affiliateUserName),
      (data) => data
    );
  },
  async login(_, { email, password }) {
    return handleAction(
      () =>
        this.$api.handleResponse(
          this.$auth.loginWith('local', { data: { email, password } })
        ),
      () => 'Welcome! You are now logged in.'
    );
  },
  async signup(_, user) {
    return handleAction(
      () => this.$api.signup(user),
      async (data) => {
        await handleAction(
          () =>
            this.$api.handleResponse(
              this.$auth.loginWith('local', { data: user })
            ),
          () => data
        );
        return data;
      }
    );
  },
  async publisherSignup(_, publisher) {
    return handleAction(
      () => this.$api.signupPublisher(publisher),
      async (data) => {
        await handleAction(
          () =>
            this.$api.handleResponse(
              this.$auth.loginWith('local', { data: publisher })
            ),
          () => data
        );
        return data;
      }
    );
  },
  async affiliateSignup(_, affiliate) {
    // TODO: country must be outside the personal object like account type to match the new api structure
    return handleAction(
      () => this.$api.signupAffiliate(affiliate),
      async (data) => {
        await handleAction(
          () =>
            this.$api.handleResponse(
              this.$auth.loginWith('local', {
                data: affiliate.payload.personal,
              })
            ),
          () => data
        );
        return data;
      }
    );
  },
  async updateProfile(_, { fullName }) {
    return handleAction(
      () => this.$api.updateUserProfile(fullName),
      (data) => data
    );
  },
  async updateProfilePicture(_, { formData }) {
    return handleAction(
      () => this.$api.uploadProfilePicture(formData),
      (data) => data
    );
  },
  async updateUserPassword(_, { password, newPassword, email }) {
    return handleAction(
      () => this.$api.updateUserPassword(email, password, newPassword),
      (data) => {
        handleAction(
          () =>
            this.$api.handleResponse(
              this.$auth.loginWith('local', {
                data: { email, password: newPassword },
              })
            ),
          () => data
        );
        return data;
      }
    );
  },
  async loadApps() {
    return handleAction(
      () => this.$api.getUserApps(),
      (data) => data
    );
  },
  async followUser(_, uuid) {
    return handleAction(
      () => this.$api.followUser(uuid),
      (data) => data
    );
  },
  async reportUser(_, { payload, uuid }) {
    return handleAction(
      () => this.$api.reportUser(uuid, payload),
      (data) => data
    );
  },
  async migrateUserToPublisher(_) {
    return handleAction(
      () => this.$api.migrateUserToPublisher(),
      (data) => data
    );
  },
};
