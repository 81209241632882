/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import { handleAction } from './helpers';

export const state = () => ({
  selectedPlan: null,
  selectedProduct: null,
  selectedProductVideoUrl: null,
  selectedProductName: null,
  selectedProductSlug: null,
  emailToken: null,
  emailId: null,
  isCookieAccepted: false,
  referral: null,
  discount: null,
  isStripeSessionValidated: undefined,
  appsManagerMetaData: null,
});

export const getters = {
  getAppsManagerMetaData: (state) => state.appsManagerMetaData,
  getAffiliateDiscount: (state) => state.discount,
};

export const mutations = {
  SET_STRIPE_SESSION_VALIDATION(state, response) {
    state.isStripeSessionValidated = response;
  },
  SET_COOKIE(state, response) {
    state.isCookieAccepted = response;
  },
  SET_PLAN(state, plan) {
    state.selectedPlan = plan;
  },
  SET_PRODUCT(state, product) {
    state.selectedProduct = product;
  },
  SET_PRODUCT_VIDEO_URL(state, videoUrl) {
    state.selectedProductVideoUrl = videoUrl;
  },
  SET_PRODUCT_VIDEO_NAME(state, productName) {
    state.selectedProductName = productName;
  },
  SET_PRODUCT_VIDEO_SLUG(state, productSlug) {
    state.selectedProductSlug = productSlug;
  },
  SET_EMAIL_TOKEN(state, emailToken) {
    state.emailToken = emailToken;
  },
  SET_EMAIL_ID(state, emailId) {
    state.emailId = emailId;
  },
  SET_DISCOUNT(state, discount) {
    state.discount = discount;
  },
  SET_APPS_MANAGER_METADATA(state, metaData) {
    state.appsManagerMetaData = metaData;
  },
};

export const actions = {
  setProductAndPlan({ commit }, { plan, product }) {
    commit('SET_PLAN', plan);
    commit('SET_PRODUCT', product);
    return [true, undefined];
  },
  setTokenAndId({ commit }, { id, token }) {
    commit('SET_EMAIL_ID', id);
    commit('SET_EMAIL_TOKEN', token);
    return [true, undefined];
  },
  setDiscount({ commit }, discount) {
    commit('SET_DISCOUNT', discount);
    return [true, undefined];
  },
  setStripeSessionValidation({ commit }, status) {
    commit('SET_STRIPE_SESSION_VALIDATION', status);
    return [true, undefined];
  },
  setSubscription({ commit }, subscription) {
    commit('SET_SUBSCRIPTION', subscription);
  },
  setProductDetails({ commit }, { videoUrl, slug, name }) {
    commit('SET_PRODUCT_VIDEO_NAME', name);
    commit('SET_PRODUCT_VIDEO_SLUG', slug);
    commit('SET_PRODUCT_VIDEO_URL', videoUrl);
  },
  resetStore({ commit }, { videoUrl, slug, name, id, token, plan, product }) {
    commit('SET_PRODUCT_VIDEO_NAME', name);
    commit('SET_PRODUCT_VIDEO_SLUG', slug);
    commit('SET_PRODUCT_VIDEO_URL', videoUrl);
    commit('SET_EMAIL_ID', id);
    commit('SET_EMAIL_TOKEN', token);
    commit('SET_PLAN', plan);
    commit('SET_PRODUCT', product);
  },
  async validateStripeSession(_, { id }) {
    return handleAction(
      () => this.$api.validatePostStripeCheckout(id),
      (data) => data
    );
  },
  async loadAppsManagerMetaData({ commit }) {
    return handleAction(
      () => this.$api.getAppsManagerMetadata(),
      (data) => {
        commit('SET_APPS_MANAGER_METADATA', data.body);
        return data.body;
      }
    );
  },
  async affiliateCountUp(_, { userName }) {
    return handleAction(
      () => this.$api.incrementAffiliateClicks(userName),
      (data) => data.body
    );
  },
  async getRecaptchaScore(_, { token }) {
    return handleAction(
      () => this.$api.getRecaptchaScore(token),
      (data) => data
    );
  },
  async checkEmailAvailability(_, { email }) {
    return handleAction(
      () => this.$api.checkEmailAvailability(email),
      (data) => data
    );
  },
  async checkUsernameAvailability(_, { userName }) {
    return handleAction(
      () => this.$api.checkUsernameAvailability(userName),
      (data) => data
    );
  },
};
