
import uiMixin from '@/mixins/uiMixin';

export default {
  mixins: [uiMixin],
  props: {
    days: {
      required: false,
      type: Number,
      default: 0,
    },
    hours: {
      required: false,
      type: Number,
      default: 0,
    },
    minutes: {
      required: false,
      type: Number,
      default: 0,
    },
    seconds: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  methods: {
    // TODO: add disclaimer about VAT payment
    async selectPlan() {
      // onProductSelectionClick(this, {
      //   productUUID: this.$config.mrEBPremiumUuid,
      //   productShortCode: 'eb1',
      // });
    },
  },
};
