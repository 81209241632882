// store/api.js

export default ($mrApi, $mrApiV2, $axios) => ({
  // Helper to extract data and handle errors consistently
  handleResponse(response) {
    return response
      .then((res) => [res, undefined]) // If successful, return data and no error
      .catch((error) => [undefined, error]); // On error, return no data and the error
  },

  // Products
  getProducts(isLoggedIn) {
    const endpoint = isLoggedIn
      ? '/publisher/profiles/logged-in'
      : '/publisher/profiles';
    return this.handleResponse($mrApi.get(endpoint));
  },
  createProductReview(uuid, payload) {
    return this.handleResponse(
      $mrApiV2.post(`/product/review/${uuid}`, payload)
    );
  },
  addProductView(uuid) {
    return this.handleResponse($mrApiV2.post(`/product/clicks/${uuid}`));
  },
  getSingleProduct(isLoggedIn, permalink) {
    const endpoint = isLoggedIn
      ? `/product/logged-in/${permalink}`
      : `/product/${permalink}`;
    return this.handleResponse($mrApiV2.get(endpoint));
  },
  getSingleAuthor(userName) {
    return this.handleResponse($mrApi.get(`/publisher/profile/${userName}`));
  },
  reportProduct(uuid, payload) {
    return this.handleResponse(
      $mrApiV2.post(`/report/product/${uuid}`, payload)
    );
  },
  likeUnlikeProduct(uuid) {
    return this.handleResponse($mrApiV2.post(`/product/like-unlike/${uuid}`));
  },
  createFreeProductSubscription(uuid, payload) {
    return this.handleResponse(
      $mrApiV2.post(`/subscription/free/${uuid}`, payload)
    );
  },
  requestStripeSessionCreation(uuid, payload) {
    return this.handleResponse(
      $mrApiV2.post(`/stripe/session/${uuid}`, payload)
    );
  },

  // Posts
  getBlogPosts() {
    return this.handleResponse($mrApi.get('/blog'));
  },
  getBlogPost(id) {
    return this.handleResponse($mrApi.get(`/blog/${id}`));
  },

  // Global
  incrementAffiliateClicks(userName) {
    return this.handleResponse($mrApi.$post(`/affiliate/clicks/${userName}`));
  },
  getAppsManagerMetadata() {
    return this.handleResponse($mrApiV2.$get('/apps-manager/metadata'));
  },
  validatePostStripeCheckout(id) {
    return this.handleResponse($mrApi.$get(`/stripe/session/validate/${id}`));
  },

  // User
  followUser(uuid) {
    return this.handleResponse($mrApi.$post(`/user/follow/${uuid}`));
  },
  reportUser(uuid, payload) {
    return this.handleResponse($mrApiV2.$post(`/report/user/${uuid}`, payload));
  },
  requestEmailVerificationToken() {
    return this.handleResponse($mrApi.$post('/token/confirmation/generate'));
  },
  requestNewEmailVerificationToken(email) {
    return this.handleResponse(
      $mrApi.$post('/token/confirmation/regenerate', { email })
    );
  },
  verifyEmailVerificationToken(token, uuid) {
    return this.handleResponse(
      $mrApi.$post('/token/confirmation/verify', { token, uuid })
    );
  },
  requestPasswordRecoverToken(email) {
    return this.handleResponse(
      $mrApi.$post('/token/password-reset/generate', { email })
    );
  },
  verifyNewPassword(uuid, password, token) {
    return this.handleResponse(
      $mrApi.$post('/token/password-reset/verify', { uuid, password, token })
    );
  },
  sendContactMessage(selectedTopic, selectedApp, name, email, issue, message) {
    return this.handleResponse(
      $mrApi.$post('/contact', {
        selectedTopic,
        selectedApp,
        name,
        email,
        issue,
        message,
      })
    );
  },
  signup(user) {
    return this.handleResponse($mrApi.$post('/user', user));
  },
  signupPublisher(publisher) {
    return this.handleResponse($mrApi.$post('/publisher', publisher));
  },
  signupAffiliate(affiliate) {
    return this.handleResponse($mrApi.$post('/affiliate', affiliate));
  },
  updateUserProfile(fullName) {
    return this.handleResponse($mrApi.$put('/user', { fullName }));
  },
  uploadProfilePicture(formData) {
    return this.handleResponse(
      $mrApi.$post('/upload/picture/user', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    );
  },
  updateUserPassword(email, password, newPassword) {
    return this.handleResponse(
      $mrApi.$put('/user/password', { email, password, newPassword })
    );
  },
  getUserApps() {
    return this.handleResponse($mrApi.$get('/user/apps'));
  },
  requestStripeCustomerPortal(stripeCustomerId) {
    return this.handleResponse(
      $mrApi.$post('/stripe/portal/session', { stripeCustomerId })
    );
  },
  requestApplicationDownloadToken(subscriptionUUID) {
    return this.handleResponse(
      $mrApi.$post('/token/download/generate', { subscriptionUUID })
    );
  },
  verifyApplicationDownloadToken(downloadToken, uuid) {
    return this.handleResponse(
      $mrApi.$post('/token/download/verify', { downloadToken, uuid })
    );
  },
  subscribeUserToProduct(uuid, affiliateUserName) {
    return this.handleResponse(
      $mrApi.$post(`/subscribe/${uuid}`, { affiliateUserName })
    );
  },
  migrateUserToPublisher() {
    return this.handleResponse($mrApi.$post(`/user/migrate/publisher/`));
  },
  getRecaptchaScore(token) {
    return this.handleResponse($axios.$get(`/recaptcha/${token}`));
  },
  checkEmailAvailability(email) {
    return this.handleResponse($axios.$get(`/email/${email}`));
  },
  checkUsernameAvailability(username) {
    return this.handleResponse($axios.$get(`/username/${username}`));
  },
});
