
// eslint-disable-next-line import/no-unresolved, import/extensions
import uiMixin from '@/mixins/uiMixin';

export default {
  mixins: [uiMixin],

  data() {
    return {
      footerSections: [
        {
          title: 'Security & Privacy',
          resources: [
            { title: 'Terms of Sevices', url: '/terms-of-service' },
            { title: 'Refund Policy', url: '/refund-policy' },
            { title: 'Privacy Policy', url: '/privacy-policy' },
          ],
        },
        {
          title: 'Resources',
          resources: [
            { title: 'Authors', url: '/authors' },
            { title: 'Affiliate Program', url: '/affiliate' },
            { title: 'Blogs', url: '/blog' },
            { title: 'Help Center', url: '/docs' },
            { title: 'FAQ', url: '/faq' },
            { title: 'Status', url: this.$config.mrStatusBaseUrl },
          ],
        },
      ],
      footerBtn: [
        {
          icon: 'mdi-facebook',
          link: 'https://www.facebook.com/MotionRoutes/',
        },
        {
          icon: 'mdi-youtube',
          link: 'https://www.youtube.com/channel/UCbc_cGZ8Jryu3Hs-4rnNgKw',
        },
        {
          icon: 'mdi-twitter',
          link: 'https://www.twitter.com/MotionRoutes',
        },
        {
          icon: 'mdi-instagram',
          link: 'https://www.instagram.com/motionroutes',
        },
      ],
    };
  },
  methods: {
    toHomePage() {
      this.$router.push('/');
    },
    getLinkAttributes(link) {
      if (link.url.startsWith('/')) {
        // Internal link using Vue Router
        return { to: this.localePath(link.url) };
      }
      // External link using href
      return { href: link.url, target: '_blank' };
    },
  },
};
