/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
// store/products.js

import {
  convertPublisherProfile,
  convertAndPullKeys,
  handleAction,
} from './helpers';

// State
export const state = () => ({
  list: [],
  authors: [],
  product: null,
  author: null,
  selectedProduct: null,
  selectedAuthor: null,
  productsLoaded: false,
  authorsLoaded: false,
  authorLoaded: false,
  productLoaded: false,
});

// Getters
export const getters = {
  getProductById: (state) => (id) =>
    state.list.find((product) => product.id === id),
  getAuthorById: (state) => (id) =>
    state.authors.find((author) => author.id === id),
  getProducts: (state) => state.list,
  getProduct: (state) => state.product,
  getSelectedProduct: (state) => state.selectedProduct,
  getAuthors: (state) => state.authors,
  getAuthor: (state) => state.author,
  getProductsLoaded: (state) => state.productsLoaded,
  getAuthorsLoaded: (state) => state.authorsLoaded,
  getAuthorLoaded: (state) => state.authorLoaded,
  getProductLoaded: (state) => state.productLoaded,
};

// Mutations
export const mutations = {
  SET_PRODUCTS(state, list) {
    state.list = list;
  },
  SET_SELECTED_PRODUCT(state, selectedProduct) {
    state.selectedProduct = selectedProduct;
  },
  SET_PRODUCT(state, product) {
    state.product = product;
  },
  SET_AUTHORS(state, authors) {
    state.authors = authors;
  },
  SET_AUTHOR(state, author) {
    state.author = author;
  },
  SET_SELECTED_AUTHOR(state, selectedAuthor) {
    state.selectedAuthor = selectedAuthor;
  },
  TOGGLE_FOLLOW_AUTHOR(state, { authorUuid, isFollowing }) {
    const selectedAuthor = state.author;
    selectedAuthor.followers = isFollowing
      ? selectedAuthor.followers + 1
      : selectedAuthor.followers - 1;
    selectedAuthor.isFollowing = isFollowing;

    const author = state.authors.find((a) => a.id === authorUuid);
    if (author) {
      author.followers = isFollowing
        ? author.followers + 1
        : author.followers - 1;
      author.isFollowing = isFollowing;
    }
  },
  TOGGLE_LIKE_PRODUCT(state, productUuid) {
    const product = state.list.find((a) => a.uuid === productUuid);
    if (product) {
      product.likes = product.isLiked ? product.likes - 1 : product.likes + 1;
      product.isLiked = !product.isLiked;
    }
  },
  SET_PRODUCTS_LOADED(state, value) {
    state.productsLoaded = value;
  },
  SET_AUTHORS_LOADED(state, value) {
    state.authorsLoaded = value;
  },
  SET_AUTHOR_LOADED(state, value) {
    state.authorLoaded = value;
  },
  SET_PRODUCT_LOADED(state, value) {
    state.productLoaded = value;
  },
};

// Actions
export const actions = {
  toggleFollowAuthor({ commit }, { authorUuid, isFollowing }) {
    commit('TOGGLE_FOLLOW_AUTHOR', { authorUuid, isFollowing });
  },
  toggleLikeProduct({ commit }, productUuid) {
    commit('TOGGLE_LIKE_PRODUCT', productUuid);
  },
  setSelectedProduct({ commit }, product) {
    commit('SET_SELECTED_PRODUCT', product);
  },
  async load({ commit, rootState }) {
    return handleAction(
      () => this.$api.getProducts(rootState.auth.loggedIn),
      (data) => {
        // TODO: remove the nested data from api to make it more readable across all stores
        const authors = data.data.body.publishers.map(convertPublisherProfile);
        commit('SET_AUTHORS', authors);
        commit('SET_AUTHORS_LOADED', true);

        const allProducts = authors.flatMap((author) => author.products || []);
        commit('SET_PRODUCTS', allProducts);
        commit('SET_PRODUCTS_LOADED', true);

        return true;
      }
    );
  },

  async createProductReview({ commit, state }, { uuid, payload }) {
    return handleAction(
      () => this.$api.createProductReview(uuid, payload),
      (data) => {
        const updatedProduct = data.data.body;
        const productList = state.list.map((product) => {
          if (product.uuid === uuid) {
            return {
              ...product,
              reviews: updatedProduct.reviews.map((review) => ({
                username: review.user.userName,
                rating: review.rating,
                date: new Date(review.createdAt).toISOString().split('T')[0],
                comment: review.message,
              })),
            };
          }
          return product;
        });
        commit('SET_PRODUCTS', productList);
        return data.data;
      }
    );
  },

  async addProductView(_, uuid) {
    return handleAction(
      () => this.$api.addProductView(uuid),
      (data) => data
    );
  },

  async loadSingleProduct({ commit, rootState }, permalink) {
    return handleAction(
      () => this.$api.getSingleProduct(rootState.auth.loggedIn, permalink),
      (data) => {
        const extractedObject = convertAndPullKeys(data.data.body);
        commit('SET_PRODUCT', extractedObject);
        commit('SET_PRODUCT_LOADED', true);
        return extractedObject;
      }
    );
  },

  async loadSingleAuthor({ commit }, userName) {
    return handleAction(
      () => this.$api.getSingleAuthor(userName),
      (data) => {
        const extractedObject = convertPublisherProfile(
          data.data.body.publisher
        );
        commit('SET_AUTHOR', extractedObject);
        commit('SET_AUTHOR_LOADED', true);
        return extractedObject;
      }
    );
  },

  async reportProduct(_, { uuid, payload }) {
    return handleAction(
      () => this.$api.reportProduct(uuid, payload),
      (data) => data.data
    );
  },

  async likeUnlikeProduct(_, uuid) {
    return handleAction(
      () => this.$api.likeUnlikeProduct(uuid),
      (data) => data.data
    );
  },

  async createFreeProductSubscription(_, { uuid, payload }) {
    return handleAction(
      () => this.$api.createFreeProductSubscription(uuid, payload),
      (data) => data.data
    );
  },

  async requestStripeSessionCreation(_, { uuid, payload }) {
    return handleAction(
      () => this.$api.requestStripeSessionCreation(uuid, payload),
      (data) => data.data
    );
  },
};
