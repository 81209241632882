
import { mapState, mapGetters } from 'vuex';
import NavBar from '@/components/NavBar.vue';
import uiMixin from '@/mixins/uiMixin';
import FooterSection from '../components/FooterSection.vue';

export default {
  mixins: [uiMixin],
  name: 'Default',
  components: {
    NavBar,
    FooterSection,
  },
  middleware: ['isAffiliated', 'getDiscount'],

  data() {
    return {
      isStoreDiscounted: false,
      // isAffiliateReferral: true,
      screenSizeDetected: false,
      cookie: true,
      drawer: false,
      showMessage: false,
      messageText: '',
      messageType: 'warning',
      resendLoading: false,
      group: null,
      categoryIcons: {
        'Apps Manager': '/../img/icons/Apps-Manager.png',
        'After Effects': '/../img/icons/After Effects-24.png',
        'Premiere Pro': '/../img/icons/Premiere-24.png',
        Photoshop: '/../img/icons/Photoshop-24.png',
        // Add more categories and icon links as needed
      },
      languages: [
        { title: 'English', img: '/img/flag_en.svg', iso: 'en' },
        { title: 'Français', img: '/img/flag_fr.svg', iso: 'fr' },
        { title: 'العربية', img: '/img/flag_ar.svg', iso: 'ar' },
      ],
      drawerLinks: [
        { title: 'pages.home', url: '/', icon: 'mdi-home' },
        // {
        //   title: 'pages.products',
        //   url: '/apps/',
        //   icon: 'mdi-crown-outline',
        // },
        {
          title: 'pages.appsManager',
          url: '/apps-manager/getting-started',
          icon: 'mdi-apps',
        },
        { title: 'pages.authors', url: '/authors', icon: 'mdi-account-group' },
        // { title: "Apps", url: "/apps" , icon: "mdi-"},
        {
          title: 'pages.startSelling',
          url: '/start-selling',
          icon: 'mdi-bullhorn-outline',
        },
        { title: 'pages.affiliate', url: '/affiliate', icon: 'mdi-handshake' },
        { title: 'pages.faq', url: '/faq', icon: 'mdi-help-circle-outline' },
        { title: 'pages.blog', url: '/blog', icon: 'mdi-post-outline' },
        {
          title: 'pages.docs',
          url: '/docs',
          icon: 'mdi-text-box-check-outline',
        },
        { title: 'pages.contact', url: '/contact', icon: 'mdi-email-outline' },
        { title: 'buttons.signup', url: '/signup', icon: 'mdi-account-plus' },
        { title: 'buttons.login', url: '/login', icon: 'mdi-login' },
      ],
      // drawerLoggedInLinks: [
      //   { title: 'pages.home', url: '/', icon: 'mdi-home' },
      //   // {
      //   //   title: 'pages.products',
      //   //   url: '/apps/',
      //   //   icon: 'mdi-crown-outline',
      //   // },
      //   {
      //     title: 'pages.appsManager',
      //     url: '/apps-manager/getting-started',
      //     icon: 'mdi-apps',
      //   },
      //   { title: 'pages.authors', url: '/authors', icon: 'mdi-account-group' },
      //   // { title: "Apps", url: "/apps" , icon: "mdi-"},

      //   {
      //     title: 'pages.startSelling',
      //     url: '/start-selling',
      //     icon: 'mdi-bullhorn-outline',
      //   },
      //   { title: 'pages.affiliate', url: '/affiliate', icon: 'mdi-handshake' },
      //   { title: 'pages.faq', url: '/faq', icon: 'mdi-help-circle-outline' },
      //   { title: 'pages.blog', url: '/blog', icon: 'mdi-post-outline' },
      //   {
      //     title: 'pages.docs',
      //     url: '/docs',
      //     icon: 'mdi-text-box-check-outline',
      //   },
      //   { title: 'pages.contact', url: '/contact', icon: 'mdi-email-outline' },
      //   { title: 'Dashboard', url: '/dashboard', icon: 'mdi-view-dashboard' },
      //   { title: 'pages.settings', url: '/user/settings', icon: 'mdi-cog' },
      //   { title: 'buttons.logout', url: '/', icon: 'mdi-logout' },
      // ],
    };
  },

  mounted() {
    this.$vuetify.rtl = this.$i18n.locale === 'ar';

    this.cookie =
      this.$auth.$storage.getUniversal('isCookieAccepted') === undefined;
    this.countDown();
    this.isScreenSizeDetected();
    if (!this.$isServer) {
      window.addEventListener('resize', this.isScreenSizeDetected);
    }
  },
  beforeDestroy() {
    // Remove event listener when component is destroyed
    if (!this.$isServer) {
      window.removeEventListener('resize', this.isScreenSizeDetected);
    }
  },
  methods: {
    exploreDiscounts() {
      // Check if we're already on the home page
      if (this.$route.path === '/') {
        // Just scroll to the element
        const element = document.getElementById('explore-products');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // Navigate to home page and scroll after the page has loaded
        this.$router.push({
          path: '/',
          hash: '#explore-products',
        });
        // Wait for the page to fully load, then scroll
        setTimeout(() => {
          const element = document.getElementById('explore-products');
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300); // Adjust timeout as necessary
      }
    },
    isScreenSizeDetected() {
      if (!this.$isServer) {
        // Check if the screen size is detected
        this.screenSizeDetected =
          window.innerWidth > 0 && window.innerHeight > 0;
      }
    },

    countDown() {
      const timer = setInterval(() => {
        const now = new Date().getTime();
        const end = new Date(this.$config.mrSaleEndDate).getTime();

        const distance = end - now;

        if (distance < 0) {
          this.expired = true;
          clearInterval(timer);
          return;
        }
        this.expired = false;

        this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
        this.hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
      }, 1000);
    },
    acceptCookie() {
      this.$auth.$storage.setUniversal('isCookieAccepted', true);
      this.cookie = false;
    },
    declineCookie() {
      this.$auth.$storage.setUniversal('isCookieAccepted', false);
      this.cookie = false;
    },
    async logout() {
      await this.$auth.logout();
      this.$toast.success("You've been logged out. See you next time!");
      this.$sentry.configureScope((scope) => scope.setUser(null));
      this.$router.push('/');
    },
    async sendVerification() {
      this.resendLoading = true;
      const [data, error] = await this.$store.dispatch(
        'user/requestEmailVerificationToken'
      );
      this.resendLoading = false;

      if (error) {
        this.$toast.error(error.message);
        return;
      }

      if (data) {
        this.$toast.success(data.message);
      }
    },

    // setLocale(locale) {
    //   // TODO: set locale to store
    //   this.$auth.$storage.setUniversal('lang', locale);
    //   this.$i18n.locale = locale;
    //   this.$vuetify.rtl = this.$i18n.locale === 'ar';

    //   // this.switchLocalePath(locale);
    //   // this.$router.push({ params: { lang: locale } });
    // },
  },

  computed: {
    ...mapGetters('global', ['getExploreProductsRef', 'getAffiliateDiscount']),
    ...mapState({
      email: (state) => state.user.email,
      showEmailNotVerified: (state) => {
        if (state.auth.loggedIn && state.auth.user.status === 'non_verified') {
          return true;
        }
        return false;
      },
    }),
    isAffiliateReferral() {
      // return true
      return this.getAffiliateDiscount && this.getAffiliateDiscount > 0;
    },
    drawerLoggedInLinks() {
      const links = [
        { title: 'pages.home', url: '/', icon: 'mdi-home' },
        {
          title: 'pages.appsManager',
          url: '/apps-manager/getting-started',
          icon: 'mdi-apps',
        },
        { title: 'pages.authors', url: '/authors', icon: 'mdi-account-group' },
        {
          title: 'pages.startSelling',
          url: '/start-selling',
          icon: 'mdi-bullhorn-outline',
        },
        { title: 'pages.affiliate', url: '/affiliate', icon: 'mdi-handshake' },
        { title: 'pages.faq', url: '/faq', icon: 'mdi-help-circle-outline' },
        { title: 'pages.blog', url: '/blog', icon: 'mdi-post-outline' },
        {
          title: 'pages.docs',
          url: '/docs',
          icon: 'mdi-text-box-check-outline',
        },
        { title: 'pages.contact', url: '/contact', icon: 'mdi-email-outline' },
        { title: 'buttons.logout', url: '/', icon: 'mdi-logout' },
      ];

      if (this.$auth.loggedIn) {
        const userRoles = this.$auth.user?.roles || [];

        // Show settings link for users with 'client' role
        if (userRoles.includes('client')) {
          const settingsLink = {
            title: 'pages.settings',
            url: '/user/settings',
            icon: 'mdi-cog',
          };
          links.splice(links.length - 1, 0, settingsLink);
        } else {
          // Show dashboard link for users with other roles
          const dashboardLink = {
            title: 'Dashboard',
            url: '/dashboard',
            icon: 'mdi-view-dashboard',
          };
          links.splice(links.length - 1, 0, dashboardLink);
        }
      }

      return links;
    },
  },
};
// TODO: check the auth.lang and make a persistent language with i18n
