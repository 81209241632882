export default async function ({ store, from, route }) {
  const { id } = route.params;

  // Use the ID as needed in your middleware logic
  if (store.state.posts.list.length === 0 && !from && id) {
    await store.dispatch('posts/loadSinglePost', id);
  } else {
    await store.dispatch('posts/load');
  }
}
