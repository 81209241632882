// store/posts.js
/* eslint-disable no-param-reassign */

import { handleAction } from './helpers';

export const state = () => ({
  list: [],
  selectedPost: null,
  isLoaded: false,
  userCount: 0,
  userExpressionCount: 0,
  publicExpressionCount: 0,
});

export const getters = {
  getBlogPosts: (state) => state.list || [],
  getSelectedPost: (state) => state.selectedPost || {},
};

export const mutations = {
  SET_POSTS(state, list) {
    state.list = Array.isArray(list) ? list : [list];
  },
  SET_SATS(state, count) {
    [state.userCount, state.userExpressionCount, state.publicExpressionCount] =
      count;
  },

  SET_IS_LOADED(state, value) {
    state.isLoaded = value;
  },
  SET_SELECTED_POST(state, post) {
    state.selectedPost = post;
  },
};

export const actions = {
  async load({ commit }) {
    return handleAction(
      () => this.$api.getBlogPosts(),
      (data) => {
        commit('SET_POSTS', data.data.body.blogPost);
        commit('SET_SATS', [
          data.data.body.stats.body.nbUsers,
          data.data.body.stats.body.nbExpressions,
          data.data.body.stats.body.nbPublic,
        ]);
        commit('SET_IS_LOADED', true);
      }
    );
  },
  async loadSinglePost({ commit }, id) {
    return handleAction(
      () => this.$api.getBlogPost(id),
      (data) => {
        commit('SET_SELECTED_POST', data.data.body.blogPost);
        commit('SET_IS_LOADED', true);
      }
    );
  },
};
