/* eslint-disable no-return-await */
/* eslint-disable arrow-body-style */
// eslint-disable-next-line no-unused-vars
export default async ({ $config }, locale) => {
  return await Promise.resolve({
    hello: 'Welcome back ',
    bye: 'Take care and see you soon!',
    close: 'Close',
    banner: [
      `🔥 Black Friday & Cyber Monday 60% OFF 🔥 Lifetime Access to EXPRESSION BASE deal ends in`,
      `Get Deal`,
      `d`,
      `h`,
      `m`,
      `s`,
    ],
    common: {
      recaptcha: [
        'This site is protected by reCAPTCHA and the Google',
        'Privacy Policy',
        'and',
        'Terms of Service',
        'apply.',
      ],
      credits: ['Motion Routes LTD', '. All Rights Reserved'],
      email_errors: ['E-mail is required', 'E-mail must be valid'],
      name_errors: [
        'Name is required',
        'Name must be at most 20 characters long',
      ],
      username_errors: [
        'Username is required',
        'Username must be at most 15 characters long',
        'Username must be at least 3 characters long',
        'Username must contains only letters and numbers',
      ],
      password_errors: [
        'Password is required',
        'Password must be at least 8 characters long',
      ],
    },
    pages: {
      home: 'Home',
      products: 'Products',
      authors: 'Authors',
      affiliate: 'Affiliate Program',
      contact: 'Contact',
      eb: 'Expression Base',
      resources: 'Resources',
      blog: 'Blog',
      faq: 'FAQ',
      docs: 'Help Center',
      settings: 'Settings',
      aff: 'Become Affiliate',
      dev: 'For Developers',
      lic: 'Terms of Service',
      refund: 'Refund Policy',
      privacy: 'Privacy Policy',
      appsManager: 'Apps Manager',
      startSelling: 'Start Selling',
    },

    buttons: {
      login: 'Login',
      signup: 'Signup',
      logout: 'Logout',
      download_now: 'Download Now',
      learn_more: 'Learn More',
      explore_more: 'Explore More',
      start_now: 'Start Now',
      version_win: 'v1.0.0 | 100MB',
      version_mac: 'beta | 100MB',
      download: 'free Download',
      start_selling: 'Start Selling',
      get_started_now: 'Get Started Now',
      become_affilate: 'Become an Affiliate',
    },
    index: {
      title1: 'Speed up',
      title2: 'your After Effects Workflow',
      subtitle1: 'Boost Your After Effects Workflow with the Power of',
      subtitle2: 'Expression Base!',
      cards: [
        {
          title: 'Faster Animation Workflow',
          description:
            'Use Adobe After Effects Expression Engine to render your animation automatically without the need for unnecessary keyframes.',
        },
        {
          title: 'Public Expressions For Everyone',
          description:
            'Access Top level Public Expressions from our After Effects Gurus to enhance and speed up your animation workflow.',
        },
        {
          title: 'Massive Expressions Injection',
          description:
            'Inject endless amount of expressions into endless amount of properties within endless amount of layers.',
        },
        {
          title: 'Smart Expression Controls',
          description:
            'Use our predefined Smart Expression Controls to instantly name, create and link your expressions to any expression control.',
        },
        {
          title: 'Access Expressions From Anywhere',
          description:
            'Our highly available servers will ensure a fast and smooth user experience when using Expression Base from any device.',
        },
        {
          title: 'Master Layer Control',
          description:
            'Link all of your expressions and expressions controls within different properties and layers, to one single Master Layer Control.',
        },
      ],
      stats: [
        {
          title: 'Users Expressions',
        },
        {
          title: 'Public Expressions',
        },
        {
          title: 'Happy Users',
        },
      ],
      steps: [
        {
          title: 'Download & Update',
          description:
            'Seamlessly explore and upgrade Adobe products for constant access to the newest versions.',
          icon: 'mdi-cloud-download',
        },
        {
          title: 'Install & Uninstall',
          description:
            'Swiftly manage Adobe products installation or removal, streamlining digital tool control.',
          icon: 'mdi-database-plus',
        },
        {
          title: 'Activate & Deactivate',
          description:
            'Effortlessly enable or disable Adobe products, retaining license control through a simple click.',
          icon: 'mdi-lock-open-variant',
        },
        {
          title: 'System Support',
          description:
            'Ensure peak performance on MacOS Catalina (10.15+) and Windows 10 (64-bit).',
          icon: 'mdi-lan-check',
        },
      ],
      callout: 'Ready to take your workflow to the next level?',
    },
    download_apps: {
      title1: 'Get Started',
      title2: 'with Apps Manager',
      title3:
        'Watch our demo video tutorial to get a visual walkthrough of how to use Apps Manager effectively.',
      title4: 'Simplify Your Adobe Product Management',
      title5: 'System Requirements',
      subtitle1: 'Manage, Install, and Activate your Adobe products.',
      subtitle2:
        'Apps Manager is a powerful desktop application designed to simplify the management of your Adobe products.',
      subtitle3:
        'The tutorial covers the main features and functionalities, giving you a clear understanding of how to navigate and utilize the application.',
      subtitle4:
        'Before installing Apps Manager, ensure that your system meets the minimum requirements for optimal performance.',
      span1: 'Already installed?',
      span2: 'Skip Download',
      span3: 'See Tutorial',
      cards: [
        {
          title: 'Download & Update',
          description:
            'Effortlessly download and update Adobe products. Browse and discover products within Apps Manager and Download to get your desired product, and enjoy automatic updates for the latest versions.',
          icon: 'mdi-cloud-download',
        },
        {
          title: 'Install & Uninstall',
          description:
            'Install and uninstall Adobe products seamlessly. Just click Install in the Installed Products section to set up your desired product, and easily remove it by clicking Uninstall.',
          icon: 'mdi-database-plus',
        },
        {
          title: 'Activate & Deactivate',
          description:
            'Activate and deactivate Adobe products with ease. Click Activate to enable a product for use, and Deactivate to temporarily suspend its usage. Enjoy flexible control over your product activations.',
          icon: 'mdi-lock-open-variant',
        },
      ],
      faq: {
        title: 'Frequently Asked Questions',
        subtitle:
          'Please note that these are general FAQs, and you can customize and expand this section based on your specific application and user requirements.',
        list: [
          {
            content: [
              {
                question: 'What is Apps Manager?',
                answer: `Apps Manager is a desktop application designed to simplify the management of Adobe products. It allows users to download, install, uninstall, activate, and deactivate their purchased Adobe products from Motion Routes.`,
              },
              {
                question: 'How do I download and install Apps Manager?',
                answer: `To download and install Apps Manager, click on the download link specific to your operating system <b>(Mac or Windows)</b> at the top of this page. Follow the on-screen instructions to complete the installation process.`,
              },
              {
                question: 'Can I use Apps Manager on multiple devices?',
                answer: `Yes, you can use Apps Manager on multiple devices. Simply install Apps Manager on each device you want to manage your Adobe products from. Make sure to sign in with the same account to access your purchased products.`,
              },
              {
                question:
                  'How do I activate an Adobe product using Apps Manager?',
                answer: `To activate an Adobe product, open Apps Manager and go to the <b>"My Apps"</b> tab. Find the product you want to activate and click on the <b>"Activate"</b> button next to it. Apps Manager will handle the activation process for you.`,
              },
              {
                question:
                  'Can I deactivate an Adobe product using Apps Manager?',
                answer: `Yes, Apps Manager allows you to deactivate Adobe products. In the <b>"My Apps"</b> tab, locate the product you want to deactivate and click on the <b>"Deactivate"</b> button next to it. The product will be deactivated and can be reactivated later if needed.`,
              },
              {
                question:
                  'How do I uninstall an Adobe product with Apps Manager?',
                answer: `To uninstall an Adobe product, go to the <b>"My Apps"</b> tab in Apps Manager. Find the product you want to uninstall and click on the <b>"Uninstall"</b> button next to it. Apps Manager will guide you through the uninstallation process.`,
              },
              {
                question: 'Can I update my Adobe products using Apps Manager?',
                answer: `Yes, Apps Manager provides automatic updates for your installed Adobe products. Whenever an update is available, Apps Manager will notify you, and you can simply click on the <b>"Update"</b> button to install the latest version.`,
              },
              {
                question:
                  'Is Apps Manager compatible with the latest versions of Adobe products?',
                answer: `Yes, Apps Manager is designed to be compatible with the latest versions of Adobe products. It ensures seamless management and updates for your Adobe software.`,
              },
              {
                question: 'How can I customize the settings in Apps Manager?',
                answer: `Apps Manager provides a <b>"Settings"</b> tab where you can customize various options. You can customize the default download folder, UI display preferences, and other settings according to your preferences.`,
              },
              {
                question: ' Where can I find further assistance or support?',
                answer: `If you have any further questions or need assistance, you can visit our support page on our website. You can also reach out to our customer support team via email: <a href="mailto:${$config.mrEmailSupport}"<b>${$config.mrEmailSupport}</b></a> or live chat for prompt assistance.`,
              },
            ],
          },
        ],
      },
      sysReq: [
        {
          title: 'Supported operating systems',
          content: [
            'MacOS Catalina (version 10.15) and later',
            'Windows 10 (64-bit) version 1903 and later',
          ],
        },
        {
          title: 'Required Hardware Specifications',
          content: [
            'MacOs: 4 GB RAM and  Multicore Intel® processor with 64-bit support or Apple silicon processor',
            'Windows: 2 GB RAM and Intel®, AMD, or ARM processor with 64-bit support',
          ],
        },
        {
          title: 'Additional Requirements',
          content: [
            'Hard disk space: 300 MB of available hard-disk space; additional space is required for installation.',
            'Adobe Creative Cloud desktop app is required as an additional dependency or software prerequisite.',
          ],
        },
      ],
    },
    product_ready: {
      title1: 'was added Successfully!',
      title2:
        'Watch our demo video tutorial to get a visual walkthrough of how to use',
      subtitle1:
        "You're all set to proceed with the installation process using our Adobe Apps Manager.",
      subtitle2:
        'To proceed with the installation, please use our Adobe Apps Manager and follow the prompts to install the product.',
      subtitle3: 'Note: If would like to download Apps Manager again, please ',
      subtitle4: ' click here.',
      subtitle5:
        'The tutorial covers the main features and functionalities, giving you a clear understanding of how to navigate and utilize the product.',
    },
    become_author: {
      title1: 'Earnings',
      title2: 'Transform Your Skills into ',
      title3: 'How It Works',
      title4: 'What You Can Sell On Our Marketplace',
      title5: 'System Requirements',
      title6: 'Ready to start selling?',
      subtitle1:
        'Sell Your Adobe Scripts, Plugins, and More to a Global Audience',
      subtitle2:
        'Discover endless possibilities for selling on our platform designed for Adobe creators. From scripts to templates, unleash your creative assets and join a thriving community of Adobe enthusiasts.',
      subtitle3:
        'The tutorial covers the main features and functionalities, giving you a clear understanding of how to navigate and utilize the application.',
      subtitle4:
        'Before installing Apps Manager, ensure that your system meets the minimum requirements for optimal performance.',
      subtitle5:
        'Discover the Seamless Pathway to Selling Success in the Digital Marketplace for Adobe Scripts, Plugins, and More.',
      span1: 'Already installed?',
      span2: 'Skip Download',
      span3: 'See Tutorial',
      cards: [
        {
          title: 'Script',
          icon: 'mdi-script-text-outline',
          description:
            'Automated task instructions or code snippets specifically crafted for streamlining processes within Adobe tools.',
        },
        {
          title: 'Extension',
          icon: 'mdi-puzzle-outline',
          description:
            'Add-ons designed to expand and enhance functionalities within various Adobe software, offering additional features or tools.',
        },
        {
          title: 'Plugin',
          icon: 'mdi-toy-brick-outline',
          description:
            'External modules integrated into Adobe applications to extend capabilities, enabling users to customize and augment their workflows.',
        },
        {
          title: 'Preset',
          icon: 'mdi-tune',
          description:
            'Pre-configured settings or parameters aimed at achieving specific visual or functional outcomes in Adobe effects or editing software.',
        },
        {
          title: 'Template',
          icon: 'mdi-book-play-outline',
          description:
            'Pre-designed frameworks or layouts serving as starting points for projects in Adobe software, simplifying the creation process.',
        },
        {
          title: 'Mogrt',
          icon: 'mdi-folder-play-outline',
          description:
            'Customizable motion graphics templates intended for Adobe video editing software, enabling easy integration of animated elements.',
        },
        {
          title: 'Course',
          icon: 'mdi-video-high-definition',
          description:
            'Comprehensive educational content curated specifically for Adobe users, providing structured learning on software usage or creative techniques.',
        },
        {
          title: 'Ebook',
          icon: 'mdi-book-outline',
          description:
            'Digital instructional guides or resources tailored to Adobe software, offering insights, tips, and tutorials for users to enhance their skills.',
        },
      ],
      faq: {
        title: 'Frequently Asked Questions',
        subtitle:
          'Find Solutions to Common Queries About Getting Started and Succeeding in Our Digital Marketplace',
        list: [
          {
            content: [
              {
                question: 'How do I sign up as a seller?',
                answer: `Signing up is easy! Just click on "Start Selling" fill out your details, and submit your application. We’ll guide you through the verification process so you can begin selling in no time.
`,
              },
              {
                question: 'What types of digital goods can I sell?',
                answer: `Motion Routes accepts a variety of digital goods including Adobe scripts, extensions, plugins, presets, templates, MOGRTs, courses, and eBooks. If you create it, you can likely sell it here!`,
              },
              {
                question: 'Are there any fees for listing my products?',
                answer: `We charge a small commission on sales, which means listing is free! We believe in earning when you earn. Detailed fee structure is available in our terms of service.`,
              },
              {
                question: 'How do I get paid?',
                answer: `Payments are made monthly directly to your preferred payment method, such as PayPal or bank transfer. Ensure your payment details are updated in your account settings.`,
              },
              {
                question: 'How can I promote my products?',
                answer: `Use our built-in marketing tools to boost your product visibility. You can also take advantage of our promotional events and featured listings for increased exposure.`,
              },
              {
                question: 'What support does Motion Routes offer?',
                answer: `Our support team is available 24/7 to assist with any issues. We also offer seller resources, community forums, and live chat support to ensure you have all the help you need.`,
              },
              {
                question: 'Can buyers leave reviews for products?',
                answer: `Absolutely! Customer feedback is crucial. Buyers can rate and review products after purchase, which helps you build your reputation on the marketplace.`,
              },
              {
                question: 'Do you offer exclusivity arrangements?',
                answer: `Yes, we offer exclusive selling arrangements for sellers who wish to list their products only on Motion Routes. Contact us for more details about exclusivity benefits.`,
              },
            ],
          },
        ],
      },
      steps: [
        {
          title: '1- Sign Up & Set Up Your Shop',
          description:
            'Create your account and set up your online store. Customize your shop to reflect your brand, ensuring a professional look that attracts buyers.',
          src: '/../img/optimization.png',
        },
        {
          title: '2- Upload Your Digital Goods',
          description:
            "Add various digital products including Adobe extensions, scripts, plugins, ebooks, etc. Our platform's interface makes showcasing items easy.",
          src: '/../img/cloud-storage.png',
        },
        {
          title: '3- Reach a Global Audience',
          description:
            'Expand your reach to a global market. Use our marketing tools to enhance visibility and engage customers worldwide.',
          src: '/../img/target-audience.png',
        },
        {
          title: '4- Earn & Grow Your Business',
          description:
            'Start earning with each sale. Utilize analytics to track sales, optimize products, and grow your business.',
          src: '/../img/money-magnet.png',
        },
      ],
    },
    become_affilate: {
      title1: 'Affiliate',
      title2: 'Amplify Your Earnings as an ',
      title3: 'How It Works',
      title4: 'Maximize Your Earnings with Our Affiliate Program',
      title5: 'System Requirements',
      title6: 'Ready to start earning?',
      subtitle1:
        'Join our Affiliate Program and Earn Competitive Commissions on Every Sale',
      subtitle2:
        'Join the ranks of top-earning creators — the more you refer, the higher your rewards. Embrace our tiered commission structure and grow your income as you expand our community.',
      subtitle3:
        'The tutorial covers the main features and functionalities, giving you a clear understanding of how to navigate and utilize the application.',
      subtitle4:
        'Before installing Apps Manager, ensure that your system meets the minimum requirements for optimal performance.',
      subtitle5:
        'Discover the Seamless Pathway to Earning Success with Our Affiliate Program.',
      span1: 'Already installed?',
      span2: 'Skip Download',
      span3: 'See Tutorial',
      faq: {
        title: 'Frequently Asked Questions',
        subtitle:
          'Find instant answers to common questions, paving the way for a smooth and profitable affiliate experience.',
        list: [
          {
            content: [
              {
                question: 'How do I sign up as an affiliate?',
                answer: `Signing up is easy! Just click on "Become an Affiliate" fill out your details, and submit your application. We’ll guide you through the verification process so you can start earning in no time.
`,
              },
              {
                question: 'How is Affiliate revenue split and calculated?',
                answer: `For the purpose of this example we assume a UK seller that charges VAT as part of the total order amount on a MotionRoutes Affiliate order. </br>
                </br>
                Example Calculations: </br>
                </br>
                <em>Total order amount: $120 (The total amount charged in the order.)</em>  </br>
                <em>Product amount: $100 (The amount charged for the product.)</em>  </br>
                <em>VAT: $20 (Of the $100, the VAT for UK is 20%.)</em>  </br>
                <em>Subtotal: $100 (The amount charged for the product minus the VAT.)</em>  </br>
                <em>Affiliate commission: $20 (The commission fee for tier 1 affiliate is 20%.)</em>  </br>
                <em>Seller Revenue: $80 (The Subtotal minus the commission fee.)</em>  </br>
                </br>
                `,
              },
              {
                question: 'How do I add a withdrawal method?',
                answer: `1. Click on the payouts tab under the Finance section.</br>
              2. Click on Add Wise email address or Add a Bank Account.</br>
              3. Add your withdrawal method details</br>
              4. Click on Link To Account.</br>
              </br>
              <em>NB: You can only add one withdrawal method per account.</em>`,
              },
              {
                question:
                  'What is the difference between a promo code and an affiliate link?',
                answer: `A promo code is a special code that your followers can use to get a discount on their purchase.</br>
                It will allow you to track the number of sales and signups that your followers made.</br>
                </br>
                An affiliate link is a special link that you can share with your followers which will allow them to get a discount on their purchase.</br>
                It will allow you not only to track the number of sales and signups that your followers made, but also to track the number incoming traffic to our website.</br>`,
              },
              {
                question:
                  'What is the difference between a free user and a paid user?',
                answer: `A free user is a user who signed up for a free version of one of our products.</br>
                A paid user is a user who signed up for a paid version of one of our products.</br>
                </br>
                If a free user upgrades to a paid user, you will earn a commission on the amount of money they paid for the upgrade.</br>`,
              },
              {
                question: 'What support does Motion Routes offer?',
                answer: `Our support team is available 24/7 to assist with any issues. We also offer seller resources, community forums, and live chat support to ensure you have all the help you need.`,
              },
            ],
          },
        ],
      },
      steps: [
        {
          title: '1- Sign Up for the Affiliate Program',
          description:
            'Create your affiliate account and get your unique referral link. It’s quick, easy, and free to join.',
          src: '/../img/optimization.png',
        },
        {
          title: '2- Share Your Referral Link',
          description:
            'Promote your referral link through your website, blog, social media, or any other platform to attract potential referrals.',
          src: '/../img/promote.png',
        },
        {
          title: '3- Earn & Grow Your Commissions',
          description:
            'Each successful referral earns you a commission based on our tiered structure. The more referrals you bring, the higher your commission rate.',
          src: '/../img/money-magnet.png',
        },
        {
          title: '4- Track and Optimize Your Progress',
          description:
            'Use our analytics tools to monitor your referral performance and earnings. Optimize your strategies for better results and higher earnings.',
          src: '/../img/statistic.png',
        },
      ],
    },
    login: {
      title: 'Login to Your Account',
      email_placeholder: 'Enter your Email',
      password_placeholder: 'Enter your Password',
      remeberme: 'Remember Me',
      forget_password: 'Forgot Password?',
      donthave_account: 'Dont have an account?',
      signup_now: 'Signup Now',
      messages: [
        'Looks like you might not be a human :(',
        'reCaptcha could not be loaded correctly! Please try to reload the page',
        'All fields are required!',
      ],
    },
    signup: {
      title: 'Create your Account',
      name_placeholder: 'Your name',
      email_placeholder: 'Enter your Email',
      username_placeholder: 'Your username',
      password_placeholder: 'Your password',
      alreadyhave_account: 'Already have an account?',
      email_hint: 'We will send you an email confirmation to this address',
      name_hint: 'No more than 20 characters',
      password_hint: 'Your password is strong like Graphene',
      username_hint: 'Must be between 3 and 15 characters long',
      username_available: 'This username is available',
      username_not_available: 'This username is not available',
      email_available: 'This email is available',
      email_not_available: 'This email is not available',
      password_score_error:
        'Please choose a stronger password. Try a mix of letters, numbers, and symbols.',
      login_now: 'Login Now',
      consent: [
        'By registering, you agree to our',
        'Terms of Service ',
        ' and',
        ' Privacy Policy ',
      ],
      messages: [
        'Looks like you might not be a human :(',
        'reCaptcha could not be loaded correctly! Please try again',
        'All fields are required!',
      ],
      email_not_verified:
        'You cannot download this application unless you verify your account. Please check for a confirmation link in your email or click on the Send Verification Link button above.',
    },
    affiliateSignup: {
      title: 'Join Our Affiliate Program',
      name_placeholder: 'Your name',
      email_placeholder: 'Your email',
      username_placeholder: 'Your username',
      password_placeholder: 'Your password',
      alreadyhave_account: 'Already have an account?',
      email_hint: 'We will send you an email confirmation to this address',
      name_hint: 'No more than 20 characters',
      password_hint: 'Your password is strong like Graphene',
      username_hint: 'Must be between 3 and 15 characters long',
      username_available: 'This username is available',
      username_not_available: 'This username is not available',
      email_available: 'This email is available',
      email_not_available: 'This email is not available',
      password_score_error:
        'Please choose a stronger password. Try a mix of letters, numbers, and symbols.',
      login_now: 'Login Now',
      consent: [
        'By registering, you agree to our',
        'Terms of Service ',
        ' and',
        ' Privacy Policy ',
      ],
      messages: [
        'Looks like you might not be a human :(',
        'reCaptcha could not be loaded correctly! Please try again',
        'All fields are required!',
      ],
      email_not_verified:
        'You cannot download this application unless you verify your account. Please check for a confirmation link in your email or click on the Send Verification Link button above.',
      countries: [
        'Afghanistan',
        'Albania',
        'Algeria',
        'Andorra',
        'Angola',
        'Anguilla',
        'Antigua &amp; Barbuda',
        'Argentina',
        'Armenia',
        'Aruba',
        'Australia',
        'Austria',
        'Azerbaijan',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bermuda',
        'Bhutan',
        'Bolivia',
        'Bosnia &amp; Herzegovina',
        'Botswana',
        'Brazil',
        'British Virgin Islands',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'Cambodia',
        'Cameroon',
        'Cape Verde',
        'Cayman Islands',
        'Chad',
        'Chile',
        'China',
        'Colombia',
        'Congo',
        'Cook Islands',
        'Costa Rica',
        'Cote D Ivoire',
        'Croatia',
        'Cruise Ship',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'Denmark',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'Ecuador',
        'Egypt',
        'El Salvador',
        'Equatorial Guinea',
        'Estonia',
        'Ethiopia',
        'Falkland Islands',
        'Faroe Islands',
        'Fiji',
        'Finland',
        'France',
        'French Polynesia',
        'French West Indies',
        'Gabon',
        'Gambia',
        'Georgia',
        'Germany',
        'Ghana',
        'Gibraltar',
        'Greece',
        'Greenland',
        'Grenada',
        'Guam',
        'Guatemala',
        'Guernsey',
        'Guinea',
        'Guinea Bissau',
        'Guyana',
        'Haiti',
        'Honduras',
        'Hong Kong',
        'Hungary',
        'Iceland',
        'India',
        'Indonesia',
        'Iran',
        'Iraq',
        'Ireland',
        'Isle of Man',
        'Israel',
        'Italy',
        'Jamaica',
        'Japan',
        'Jersey',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'Kuwait',
        'Kyrgyz Republic',
        'Laos',
        'Latvia',
        'Lebanon',
        'Lesotho',
        'Liberia',
        'Libya',
        'Liechtenstein',
        'Lithuania',
        'Luxembourg',
        'Macau',
        'Macedonia',
        'Madagascar',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'Mauritania',
        'Mauritius',
        'Mexico',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'Montserrat',
        'Morocco',
        'Mozambique',
        'Namibia',
        'Nepal',
        'Netherlands',
        'Netherlands Antilles',
        'New Caledonia',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'Norway',
        'Oman',
        'Pakistan',
        'Palestine',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'Peru',
        'Philippines',
        'Poland',
        'Portugal',
        'Puerto Rico',
        'Qatar',
        'Reunion',
        'Romania',
        'Russia',
        'Rwanda',
        'Saint Pierre &amp; Miquelon',
        'Samoa',
        'San Marino',
        'Satellite',
        'Saudi Arabia',
        'Senegal',
        'Serbia',
        'Seychelles',
        'Sierra Leone',
        'Singapore',
        'Slovakia',
        'Slovenia',
        'South Africa',
        'South Korea',
        'Spain',
        'Sri Lanka',
        'St Kitts &amp; Nevis',
        'St Lucia',
        'St Vincent',
        'St. Lucia',
        'Sudan',
        'Suriname',
        'Swaziland',
        'Sweden',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'Thailand',
        `Timor L'Este`,
        'Togo',
        'Tonga',
        'Trinidad &amp; Tobago',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Turks &amp; Caicos',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'Uruguay',
        'Uzbekistan',
        'Venezuela',
        'Vietnam',
        'Virgin Islands (US)',
        'Yemen',
        'Zambia',
        'Zimbabwe',
      ],
      marketingTypes: [
        'Email',
        'Blog Posts',
        'Paid Ads',
        'Social Media',
        'Other',
      ],
      accountTypes: ['Individual', 'Business'],
      reach: [
        '0-1k impressions',
        '1k-10k impressions',
        '10k-100k impressions',
        '100k-1m impressions',
        '1m+ impressions',
      ],
    },
    forget_password: {
      title: 'Forgot Password?',
      page_hint:
        'Enter your email address so we can send you a link to reset your password.',
      email_placeholder: 'Enter your Email',
      email_hint: 'We will send you an email with a reset link',
      email_available: 'This email exists in our database',
      email_not_available: 'This email does not exist in our database',
      reset_btn: 'Reset Password',
      consent: ['Remember Your Password?', 'Login here'],
    },
    reset_password: {
      title: 'Reset Your Password',
      page_hint: 'Type your new password here.',
      password_placeholder: 'Your new password',
      password_hint: 'Your password must be at least 8 characters long',
      password_repeat_placeholder: 'Repeat your new password',
      password_repeat_hint: 'Your password must be the same as above',
      save_btn: 'save password',
      repeat_password_error: 'Passwords must be identical.',
      repeat_password_success: 'Good memory! your passwords are identical',
      invalid_token: 'Invalid Confirmation Token!',
    },
    contact: {
      title: 'Contact Us',
      q1: 'I am submitting a',
      q2: 'I am using',
      issue: 'Your subject',
      message_placeholder: 'Your message',
      issue_errrors: [
        'Subject is required',
        'Subject must be at most 30 characters long',
      ],
      message_errrors: [
        'Message is required',
        'Message must be at most 500 characters long',
      ],
      issue_hint: 'Few words to describe the motivation behind your message.',
      topics: ['Bug Report', 'Feature Request', 'Discussion'],
      email_hint: 'We will be communicating with you via this email address.',
      message_hint: 'Please be as concise as possible.',
      send_btn: 'Send',
    },
    faq: {
      title: 'Frequently Asked Questions',
      subtitle:
        'Find Answers to Common Queries About Motion Routes and Navigation',
      list: [
        {
          title: 'Account',
          content: [
            {
              question: 'Why I need to create an account on Motion Routes?',
              answer: `Every user must have a unique account on Motion Routes in order to keep records about our fellow users and prevent any spam abuse that may happen.`,
            },
            {
              question: 'How do I delete my account?',
              answer: `Contact the support team via our contact page or directly via email: <a href="mailto:${$config.mrEmailSupport}"<b>${$config.mrEmailSupport}</b></a>.`,
            },
            {
              question:
                "Can I update my account's information after I sign up?",
              answer: `Click on your initials icon in the upper right hand portion of the top navigation bar.</br>
            Click on Settings -> Profile.</br>
            You can only update your account's name and profile image.</br>
            Email address and username cannot be updated after sign up, because our system count on these records in all of our internal processes.`,
            },
            {
              question: 'Can I update my password?',
              answer: `Click on your initials icon in the upper right hand portion of the top navigation bar.</br>
            Click on Settings -> Security.</br>
            You can only update your account's password under the Password section.`,
            },
            {
              question: 'Can I share my account with others?',
              answer: `Account sharing is prohibited on Motion Routes platform and may lead to permanent account ban.`,
            },
          ],
        },
        {
          title: 'License and Using Items',
          content: [
            {
              question: 'How to Upgrade from free to paid plan?',
              answer: `You can simply click on the upgrade button inside the Adobe HTML extension or directly buy the paid version <em>with the same account</em>.</br>
            <em>Upgrading from free to paid plan with the same account will automatically update your account and bring all your old data from the free plan to the paid version.</em>`,
            },
            {
              question: 'Can I share my license with others?',
              answer: `License sharing is prohibited on all Motion Routes products and may result in Software License Violation which may lead to permanent account ban.</br>
            License sharing is only allowed on Team License for certain products.`,
            },
            {
              question: 'Can I find an old item license?',
              answer: `You can find any item license that you have already bought to by clicking on your initials icon in the upper right hand portion of the top navigation bar.</br>
            Click on Settings -> My Apps.</br>`,
            },
            {
              question: 'Can I use my license on more than one machine?',
              answer: `You can use your license on more than one machine if this feature is already included in the license. `,
            },
            {
              question: 'How can I report a broken item?',
              answer: `If you are experiencing issues while using your item, first recheck the item features and descriptions.</br>
            If you believe that the item is not working correctly as described, you can report the issue to the support team either by visiting the contact page or direct email to <a href="mailto:${$config.mrEmailSupport}">${$config.mrEmailSupport}</a>`,
            },
          ],
        },
        {
          title: 'General',
          content: [
            {
              question: 'What are the supported countries by Motion Routes?',
              answer: `Motion Routes is a global platform which operate on multiple countries, however certain countries are not supported due to laws restrictions.</br>
            Here is the list of restricted countries by Motion Routes:</br>
            <ul>
            <li>Cuba</li>
            <li>Isreal</li>
            <li>Iran</li>
            <li>Crimea Region</li>
            <li>North Korea</li>
            </ul>
            Please note that this list is subject to change by the terms and conditions of Motion Routes.`,
            },
            {
              question: 'How can I sell on Motion Routes?',
              answer: `You can simply contact the Motion Routes support team via email: <a href="mailto:${$config.mrEmailSupport}">${$config.mrEmailSupport}</a></br>
            We will provide all the tools you need to use our API and sell on Motion Routes platform.`,
            },
            {
              question: 'How can I join an affiliate program on Motion Routes?',
              answer: `You can simply contact the Motion Routes support team via email: <a href="mailto:${$config.mrEmailSupport}">${$config.mrEmailSupport}</a></br>
            You need to include the following in your email:</br>
            <ul>
            <li>Traffic source (i.e: Youtube)</li>
            <li>Estimated monthly traffic</li>
            </ul>
            An email will be sent to you within the next 24 hours which states our response towards your application.`,
            },
            {
              question: 'Can I ask for a refund?',
              answer: `Refunds are only allowed under certain conditions:</br>
            <ul>
            <li>Purchase was made for no more than 72 hours</li>
            <li>You did not subscribed to the free plan of the same item</li>
            </ul>
            Otherwise, we do not allow refunds on paid items which was bought after subscribing to a free plan, as we believe that customers have fully tried the item beforehand.`,
            },
          ],
        },

        {
          title: 'Installation',
          content: [
            {
              question: 'How to download an item on Motion Routes?',
              answer: `After successfully registering your account in Motion Routes, you can simply download any item by clicking the download button.</br>
            Or you can download any item that you have already subscribed to by clicking on your initials icon in the upper right hand portion of the top navigation bar.</br>
            Click on Settings -> My Apps.</br>`,
            },
            {
              question: 'How to install an Adobe HTML Extension?',
              answer: `<em>1) Automatic Mode: </em></br>
            Adobe HTML Extension are usually shipped in ZXP format. Thus you need a ZXP installer to install it correctly on your machine.</br>
            There are 2 free ZXP installers available on the market so far:</br>
            <ul>
            <li><a href="https://zxpinstaller.com/">Open Source ZXPInstaller</a> <i>by @maxoos</i></li>
            <li><a href="https://aescripts.com/learn/zxp-installer/">AeScript ZXPInstaller</a> <i>by @aescripts+aeplugins</i></li>
            </ul>
            <b>We are currently working on our own ZXP installer which we will eventually post for free download.</b></br>
            Drag and drop your ZXP file into the ZXP installer and let it do its magic.</br>
            <em>2) Manual Mode: </em></br>
            Sometimes you need to manually install your Adobe HTML extension, thus you need to follow these steps:</br>
            <small>On Windows:</small></br>
            Rename your .zxp extension file to use .zip instead</br>
            Unzip the file, you should get a folder. Rename it if desired</br>
            Copy that folder into C:\\Program Files (x86)\\Common Files\\Adobe\\CEP\\extensions\\</br>
            Restart After Effects</br>
            Your extension should now show up under Window > Extensions</br>
            <small>On Mac:</small></br>
            Rename your .zxp extension file to use .zip instead.</br>
            Unzip the file, you should get a folder. Rename it if desired</br>
            Copy that folder into ~/Library/Application Support/Adobe/CEP/extensions</br>
            Restart After Effects</br>
            Your extension should now show up under Window > Extensions</br>`,
            },
          ],
        },
      ],
    },
    apps: {
      eb: {
        tabs: ['Overview', 'Changelogs'],
        author: 'Author',
        version: 'Version',
        rating: 'Rating',
        nb_installs: 'Number of installs',
        os_compatibility: 'Compatibility (OS)',
        app_compatibility: 'Compatibility (App)',
        size: 'File size',
        category: 'Category',
        last_update: 'Last update',
        full_features: 'Full Features',
        features_list_breakdown: [
          {
            feature: 'Online Expression Storage',
            hint: 'Store and access all of your expressions online and anywhere.',
            learn_more_url: `${$config.mrDocsBaseUrl}/expression-base#online-expression-storage`,
            img: '/../img/01.gif',
          },
          {
            feature: 'Public Expressions Access',
            hint: 'Access top level expressions which are made by our well-known authors',
            learn_more_url: `${$config.mrDocsBaseUrl}/expression-base#public-expressions-access`,
            img: '/../img/02.gif',
          },
          {
            feature: 'Search Expressions by Name or Tags',
            hint: 'Search public expressions or your custom expressions by name or tags.',
            learn_more_url: `${$config.mrDocsBaseUrl}/expression-base#search-after-effects-expressions-by-name-or-tags`,
            img: '../img/07.gif',
          },
          {
            feature: 'Super Expressions Preloading',
            hint: 'Access all of your stored expressions on our servers with a blink of an eye.',
            learn_more_url: `${$config.mrDocsBaseUrl}/expression-base#super-after-effects-expressions-preloading`,
            img: '../img/08.gif',
          },
          {
            feature: 'Pull and Push Expressions',
            hint: 'Pull any expression from any given property and push it back.',
            learn_more_url: `${$config.mrDocsBaseUrl}/expression-base#pull-and-push-expressions`,
            img: '../img/09.gif',
          },
          {
            feature: 'Launch EB with your Custom Shortcut',
            hint: 'Setup any keys combination to launch Expressions Base for faster workflow',
            learn_more_url: `${$config.mrDocsBaseUrl}/expression-base#launch-eb-with-your-custom-shortcut`,
            img: '../img/10.gif',
          },
          {
            feature: '8 Smart Expression Controls',
            hint: 'Use our smart expression controls creator to make your expressions more modular and dynamic.',
            learn_more_url: `${$config.mrDocsBaseUrl}/expression-base#smart-expression-controls`,
            img: '../img/05.gif',
          },
          {
            feature: 'Unlimited Expression Slots',
            hint: 'Number of expressions you can store in our highly-available database.',
            learn_more_url: `${$config.mrDocsBaseUrl}/expression-base#unlimited-expression-slots`,
            img: '../img/06.gif',
          },
          // {
          //   feature: "60 days Persistent Login",
          //   hint:
          //     "Number of days you can access the application without login again.",
          //   learn_more_url: $config.mrDocsBaseUrl + "",
          //   img:
          //     "https://camo.envatousercontent.com/c4e2b69cd0003c248302fabe9500c9d4acd67c86/687474703a2f2f7777772e706c61796372656174652e67722f6674702f656e7661746f696d616765732f4b696e657469635f53697a652e676966"
          // },
          {
            feature: 'Multiple Expressions Setup',
            hint: 'Apply as many expressions as you want to one or many layers with one single click.',
            learn_more_url: `${$config.mrDocsBaseUrl}/expression-base#multiple-expressions-setup`,
            img: '../img/03.gif',
          },
          {
            feature: 'Master Layer Expressions Control',
            hint: 'Combine and store all of your expression controls in one single master layer. (Super useful for template creators)',
            learn_more_url: `${$config.mrDocsBaseUrl}/expression-base#master-layer-expressions-control`,
            img: '../img/04.gif',
          },
          {
            feature: 'Share Expressions to Public',
            hint: 'Submit and share your expressions with our community members.',
            learn_more_url: `${$config.mrDocsBaseUrl}/expression-base#share-expressions-to-public`,
            img: '../img/soon.gif',
          },
          {
            feature: 'Expressions for Teams',
            hint: 'Store, access and share your expressions with your own team members.',
            learn_more_url: `${$config.mrDocsBaseUrl}/expression-base#expressions-for-teams`,
            img: '../img/soon.gif',
          },
          // {
          //   feature: "Premium Support",
          //   hint: "Get help through our online customer support service.",
          //   learn_more_url: $config.mrDocsBaseUrl + "",
          //   img:
          //     "https://camo.envatousercontent.com/748b39fbe26d3ca82247c2e12a292505c4fc3764/687474703a2f2f7777772e706c61796372656174652e67722f6674702f656e7661746f696d616765732f4b696e657469635f436f6c6f722e676966"
          // },
          // {
          //   feature: "Lifetime Updates",
          //   hint: "Get our latest versions of our stable app.",
          //   learn_more_url: $config.mrDocsBaseUrl + "",
          //   img:
          //     "https://camo.envatousercontent.com/4b8a79809f6ddec308fa93d7194b67723e400c7c/687474703a2f2f7777772e706c61796372656174652e67722f6674702f656e7661746f696d616765732f53747265616d5f4368617474696e675f4261636b67726f756e645f312e676966"
          // }
        ],
        change_logs: [
          {
            logs: ['Improved app security'],
            version: '1.0.2',
            release_date: 'Aug 22, 2022',
          },
          {
            logs: ['Fixed Bad request issue while activating the app.'],
            version: '1.0.1',
            release_date: 'May 23, 2022',
          },
          {
            logs: ['First Release'],
            version: '1.0.0',
            release_date: 'May 15, 2022',
          },
        ],
        overview: {
          stats_title: 'Latest Application Stats',
          stats: [
            {
              title: 'Public Expressions',
              value: '108',
            },
            {
              title: 'Users Expressions',
              value: '7412',
            },
            {
              title: 'Happy Users',
              value: '2144',
            },
          ],
        },
        thankyou_page: {
          title: 'Thank You For Downloading ',

          p1: 'Please check our 📑',
          p2: 'Getting Started Guide',
          p3: 'for more information on how to use ',
          p4: '📢 Do you still have any questions? You can check our',
          p5: 'FAQ',
          p6: 'section or',
          p7: 'Contact us',
          p8: 'directly.',
        },
        pricing_model: 'fremium',
        pricing: [
          {
            title: 'Free',
            price: '0.00',
            hint: '*Free Forever',
            popular: false,
          },
          {
            title: 'Advanced',
            price: '2.99',
            hint: '*Billed Monthly',
            popular_text: 'Most Popular',
            popular: true,
          },
          {
            title: 'Premium',
            price: '69.99',
            hint: '*Lifetime Purchase',
            popular: false,
          },
        ],
        paid_pricing_features_list_breakdown: [
          {
            feature: 'Online Expression Storage',
            hint: 'Store and access all of your expressions online and anywhere.',
          },
          {
            feature: 'Public Expressions Access',
            hint: 'Access top-level expressions that are made by our well-known authors.',
          },
          {
            feature: 'Search Expressions by Name or Tags',
            hint: 'Search public expressions or your custom expressions by name or tags.',
          },
          {
            feature: 'Super Expressions Preloading',
            hint: 'Access all of your stored expressions on our servers with a blink of an eye.',
          },
          {
            feature: 'Pull and Push Expressions',
            hint: 'Pull any expression from any given property and push it back.',
          },
          {
            feature: 'Launch EB with your Custom Shortcut',
            hint: 'Setup any keys combination to launch Expressions Base for faster workflow.',
          },
          {
            feature: '8 Smart Expression Controls',
            hint: 'Use our smart expression controls creator to make your expressions more modular and dynamic.',
          },
          {
            feature: 'Unlimited Expression Slots',
            hint: 'Number of expressions you can store in our highly-available database.',
          },
          {
            feature: 'Multiple Expressions Setup',
            hint: 'Apply as many expressions as you want to one or many layers with one single click.',
          },
          {
            feature: 'Master Layer Expressions Control',
            hint: 'Combine and store all of your expression controls in one single master layer. (Super useful for template creators)',
          },
          {
            feature: 'Share Expressions to Public',
            hint: 'Submit and share your expressions with our community members.',
          },
          {
            feature: 'Expressions for Teams (soon)',
            hint: 'Store, access and share your expressions with your own team members.',
          },
          {
            feature: 'Premium Support',
            hint: 'Get help through our online customer support service.',
          },
          {
            feature: 'Lifetime Updates',
            hint: 'Get our latest versions of our stable app.',
          },
        ],
        free_pricing_features_list_breakdown: [
          {
            feature: 'Online Expression Storage',
            hint: 'Store and access all of your expressions online and anywhere.',
          },
          {
            feature: 'Public Expressions Access',
            hint: 'Access top level expressions which are made by our well-known authors.',
          },
          {
            feature: 'Search Expressions by Name or Tags',
            hint: 'Search public expressions or your custom expressions by name or tags.',
          },
          {
            feature: 'Super Expressions Preloading',
            hint: 'Access all of your stored expressions on our servers with a blink of an eye.',
          },
          {
            feature: 'Pull and Push Expressions',
            hint: 'Pull any expression from any given property and push it back.',
          },
          {
            feature: 'Launch EB with your Custom Shortcut',
            hint: 'Setup any keys combination to launch Expressions Base for faster workflow.',
          },
          {
            feature: '2 Smart Expression Controls',
            hint: 'Use our smart expression controls creator to make your expressions more modular and dynamic.',
          },
          {
            feature: '10 Expression Slots',
            hint: 'Number of expressions you can store in our highly-available database.',
          },
          {
            feature: 'Share Expressions to Public',
            hint: 'Submit and share your expressions with our community members.',
          },
          {
            feature: 'Lifetime Updates',
            hint: 'Get our latest versions of our stable app.',
          },
        ],
        common_pricing_hint: 'All Plans include these benefits:',
        common_pricing_features: [
          {
            feature: 'Online Expression Storage',
            hint: 'Store and access all of your expressions online and anywhere.',
          },
          {
            feature: 'Share Expressions to Public',
            hint: 'Submit and share your expressions with our community members.',
          },
          {
            feature: 'Public Expressions Access',
            hint: 'Access top level expressions which are made by our well-known authors',
          },
          {
            feature: 'Forum/FAQ Support',
            hint: 'Get help through our Forums and/or our Frequently Asked Questions section.',
          },
        ],
        checkout: {
          pro: {
            stripe_notice: 'Redirecting to Secure Checkout',
            stripe_subtitle:
              'Please wait while we redirect you to the secure Stripe checkout page. This should only take a few seconds.',
            timer_consent: ['Redirecting in', 'seconds...'],
          },
        },
      },
    },
    pricing_page: {
      header: ['Start for free.', 'Upgrade anytime.'],
      steps: ['Choose Your Plan', 'Create An Account', 'Confirm Your Email'],
    },
    email_confirmation: {
      title: 'Email Confirmation',
      action1: "We've just sent a verification link to your email:",
      action2:
        'Check your inbox and click the link to complete your registration and start exploring.',
      did_not_get_email1:
        "Didn't receive the email? Check your spam folder or to resend ",
      did_not_get_email2: ' to resend.',
      resend_verification_email: 'click here',
      help: 'Need help? Contact us',
    },
    email_verification: {
      title1: 'Activation Status',
      title2: 'Activate Your Account',
      resend_verification_email: 'resend confirmation link',
      loading: 'Verifying your email address...',
    },
    transaction: {
      success: {
        title1: 'Congratulations',
        title2: ' on your purchase!',
        subtitle1:
          'To get started, simply click the "Proceed to download" button below.',
        subtitle2:
          'If you have any questions or need assistance, please feel free to ',
        subtitle3: 'contact our support team.',
        consent: 'Transaction Succeeded',
        button: 'Proceed to download',
        p1: 'Action needed:',
        p2: 'Check your email',
        p3: `🎉 Congratulations! You have successfully purchased the paid version of Expression Base.
       We have sent you an email to this email address`,
        p4: 'containing your',
        p5: 'license key',
        p6: 'and your',
        p7: 'custom download link',
        p8: 'to download the app.',
        did_not_get_email: "Didn't get an email yet?",
        send_email: 'Send email again',
      },
      cancel: {
        consent1: 'Sorry! ',
        consent2: 'Transaction Canceled',
        p1: `⚠️ You have canceled this transaction. If you think that this
      action has been made by mistake, please feel free to`,
        p2: 'purchase',
        p3: 'the app again. Otherwise you can go back to',
        p4: 'home page',
      },
    },
    download_page: {
      errors: [
        'Only logged in users can download this file.',
        'Invalid Download Token!',
      ],
      preparing: 'Preparing application...',
    },
    user_settings_page: {
      tabs: [
        { title: 'Profile', icon: 'mdi-account-outline' },
        { title: 'My Products', icon: 'mdi-apps' },
        { title: 'Password', icon: 'mdi-lock-outline' },
      ],
      profile_picture_button_placeholder: 'Pick a picture',
      profile_picture: 'Profile picture',
      profile_picture_description:
        'Your profile picture will appear around Motion Routes website and applications where you contribute or are mentioned.',
      profile: 'Profile details',
      profile_description:
        'Your email address and your username are your identity in Motion Routes and are used to login.',
      name: 'Name:',
      email: 'Email address:',
      current_password_label: 'Enter current password',
      current_password_hint: 'Type your current password here',
      new_password_label: 'Enter new password',
      new_password_hint: 'Type your new password here',
      repeat_new_password_label: 'Repeat new password',
      repeat_new_password_hint: 'Repeat your new password here',

      username: 'Username:',
      password: 'Password',
      password_title: 'Password Settings:',
      password_description:
        'Changing your password will require you to login in each of your Motion Routes apps.',
      current_password: 'Current Password:',
      repeat_new_password: 'Repeat New Password:',
      new_password: 'New Password:',
      update_btn: 'Update Profile',
      empty_apps_error:
        'Sorry! You do not have any purchased products in your account.',
      empty_apps_error_action: 'Shop Now',
      stripe_disclaimer: [
        'We are currently ',
        'partnered with Stripe ',
        'to manage your Billing details securely.',
      ],
      stripe_disclaimer_action: 'Learn More',
    },
    app_card: {
      linked: 'Linked Machines:',
      author: 'Author:',
      lic: 'License key:',
      reveal_lic_btn: 'Reveal License key',
      current_plan: 'Current Plan:',
      purchase_date: 'Purchase Date:',
      status: 'Status:',
      active: 'Active',
      inactive: 'Inactive',
      billing: 'Billing:',
      auto: 'Auto',
      cancel: 'Cancels',
      download_btn: 'Download',
      manage: 'Manage Subscription',
    },
    dashboard: {
      stripe_notice: 'Redirecting to Your Dashboard',
      stripe_subtitle:
        'Please wait while we redirect you to your dashboard page. This should only take a few seconds.',
    },
  });
};
