// store/helpers.js

/**
 * Converts and pulls keys from a product object
 * @param {Object} productObj
 * @returns {Object} normalizedObj
 */
export const convertAndPullKeys = (productObj) => ({
  uuid: productObj.uuid,
  productName: productObj.activeMetadata.name || null,
  permalink: productObj.permalink || null,
  author: productObj.publisher.user.userName || null,
  authorName: productObj.publisher.user.fullName || null,
  authorImage: productObj.publisher.user.profileImageURL || null,
  authorVerified: productObj.publisher.isVerified,
  pricingModel: productObj.activeMetadata.pricingModel || null,
  price: productObj.activeMetadata.basePrice?.toFixed(2) || '0.00',
  extendedPrice: productObj.activeMetadata.extendedPrice?.toFixed(2) || '0.00',
  pricing: {
    discount: productObj.activeMetadata.discount || null,
    basePrice: productObj.activeMetadata.basePrice?.toFixed(2) || '0.00',
  },
  productImage: [
    productObj.activeMetadata.imageURL ||
      'https://oction.vercel.app/assets/img/art/62441d5e2b80a671816939.gif',
  ],
  videoURL: productObj.activeMetadata.videoURL || null,
  likes: productObj.productLikeCount || 0,
  isLiked: productObj.isLiked,
  isSubscribed: productObj.isSubscribed || [],
  promotion: {
    label: productObj.activeMetadata.promotionLabel || 'Coming Soon',
  },
  productCategory: productObj.activeMetadata.category || [],
  tags: productObj.activeMetadata.tags || [],
  views: productObj.productClicksCount || 0,
  sales: productObj.subscriptionCount || 0,
  productDescription: productObj.activeMetadata.description || null,
  changeLogs: productObj.changeLogs || [],
  reviews:
    productObj.productReviews?.map((review) => ({
      username: review.user.userName,
      rating: review.rating,
      date: new Date(review.createdAt).toISOString().split('T')[0],
      comment: review.message,
    })) || [],
  features:
    productObj.activeMetadata.features?.map((f) => ({
      feature: f.feature,
      hint: f.hint,
      type: f.type,
    })) || [],
  details: productObj.activeMetadata.details || 'No details available.',
});

/**
 * Converts raw publisher profile data
 * @param {Object} rawData
 * @returns {Object} convertedProfile
 */
export const convertPublisherProfile = (rawData) => {
  const capitalizeFirstLetter = (string) =>
    string.replace(/\b(\w)/g, (s) => s.toUpperCase());

  const convertedProfile = {
    id: rawData.user?.uuid,
    uuid: rawData.user?.uuid,
    name: rawData.companyLegalName || rawData.user?.fullName,
    userName: rawData.user?.userName,
    authorImage: rawData.user?.profileImageURL || null,
    followers: parseInt(rawData.followerCount, 10) || 0,
    following: 3,
    isFollowing: rawData.isFollowing,
    bio: rawData.bio,
    joined: new Date(rawData.user?.createdAt).toLocaleString('default', {
      month: 'long',
      year: 'numeric',
    }),
    verified: rawData.isVerified,
    products: [],
    badges: [1, 4, 16],
    location: `${capitalizeFirstLetter(
      rawData.user?.city || ''
    )}, ${capitalizeFirstLetter(rawData.user?.country || '')}`,
    socialMedia: {
      fb: rawData.socialMediaLinks?.fbURL,
      yt: rawData.socialMediaLinks?.ytURL,
      tw: rawData.socialMediaLinks?.twURL,
      ig: rawData.socialMediaLinks?.igURL,
    },
  };

  if (rawData.products && Array.isArray(rawData.products)) {
    convertedProfile.products = rawData.products.map((product) => ({
      id: product.uuid,
      uuid: product.uuid,
      productName: product.activeMetadata.name,
      permalink: product.permalink || null,
      author: rawData.user?.userName,
      authorName: rawData.user?.fullName,
      authorImage: rawData.user?.profileImageURL || null,
      authorVerified: rawData.isVerified,
      pricingModel: product.activeMetadata.pricingModel || null,
      pricing: {
        discount: product.activeMetadata.discount || 0,
        basePrice: product.activeMetadata.basePrice?.toFixed(2) || '0.00',
      },
      productImage: [
        product.activeMetadata.imageURL ||
          'https://oction.vercel.app/assets/img/art/62441d5e2b80a671816939.gif',
      ],
      videoURL: product.activeMetadata.videoURL || null,
      likes: product.productLikes?.length,
      isLiked: product.isLiked,
      isSubscribed: product.isSubscribed,
      promotion: { label: product.activeMetadata.promotionLabel || 'Freemium' },
      productCategory: product.activeMetadata.category || [],
      tags: product.activeMetadata.tags || [],
      views: product.productClicksCount || 0,
      sales: parseInt(product.subscriptionCount, 10) || 0,
      productDescription: product.activeMetadata.description,
      changeLogs:
        product.changeLogs?.map((log) => ({
          version: log.version || 'N/A',
          release_date: new Date(log.release_date).toISOString().split('T')[0],
          logs: log.logs || [],
        })) || [],
      reviews:
        product.productReviews?.map((review) => ({
          username: review.user?.userName || 'User',
          rating: review.rating,
          date: new Date(review.createdAt).toISOString().split('T')[0],
          comment: review.message,
        })) || [],
      features:
        product.activeMetadata.features?.map((feature) => ({
          feature: feature.feature,
          hint: feature.hint,
        })) || [],
      details: product.activeMetadata.details || 'No details available.',
    }));
  }

  return convertedProfile;
};

/**
 * Formats a review object
 * @param {Object} review
 * @returns {Object} formattedReview
 */
export const formatReview = (review) => ({
  username: review.user.userName,
  rating: review.rating,
  date: new Date(review.createdAt).toISOString().split('T')[0],
  comment: review.message,
});

// High-order function for handling API requests and processing responses
export async function handleAction(apiFunction, handleResponse, ...params) {
  const [data, dataError] = await apiFunction(...params);
  // Check for any error in response and handle it before processing data
  if (dataError) return [undefined, dataError.response.data.error];
  const result = await handleResponse(data); // Process the data
  return [result, undefined]; // Return the result and null for the error
}
