/* eslint-disable no-param-reassign */
/* eslint-disable import/prefer-default-export */
export const state = () => ({
  macOSVersions: [],
  windowsVersions: [],
});

function handle(promise) {
  return promise
    .then((data) => [data, undefined])
    .catch((error) => Promise.resolve([undefined, error]));
}

export const getters = {
  getMacOSVersions: (state) => state.macOSVersions || [],
  getWindowsVersions: (state) => state.windowsVersions || [],
};

export const mutations = {
  SET_ALL_MAC_OS_VERSIONS(state, versions) {
    state.macOSVersions = versions;
  },
  SET_ALL_WINDOWS_VERSIONS(state, versions) {
    state.windowsVersions = versions;
  },
};

export const actions = {
  async getAllVersions({ commit }) {
    const route = this.$mrApiV2.$get(`/apps-manager/all-versions`);
    const [data, dataError] = await handle(route);
    if (dataError) return [undefined, dataError.response.data.error];
    commit('SET_ALL_MAC_OS_VERSIONS', data.body.macos);
    commit('SET_ALL_WINDOWS_VERSIONS', data.body.windows);
    return [data, undefined];
  },
};
