
import { mapState } from 'vuex';
import uiMixin from '@/mixins/uiMixin';
import AnnouncementBanner from './UI/AnnouncementBanner.vue';

export default {
  mixins: [uiMixin],
  components: {
    AnnouncementBanner,
  },
  props: {
    drawer: Boolean,
  },
  data() {
    return {
      expired: true,
      days: 0, //
      hours: 0, //
      minutes: 0, //
      seconds: 0, //

      languages: [
        { title: 'English', img: '/img/flag_en.svg', iso: 'en' },
        { title: 'Français', img: '/img/flag_fr.svg', iso: 'fr' },
        { title: 'العربية', img: '/img/flag_ar.svg', iso: 'ar' },
      ],
      headerLinks: [
        { title: 'pages.home', url: '/', badge: false },
        // {
        //   title: 'pages.products',
        //   url: '/apps/',
        //   badge: true,
        // },
        {
          title: 'pages.startSelling',
          url: `/start-selling`,
          badge: true,
        },
        {
          title: 'pages.contact',
          url: `/contact`,
          badge: false,
        },
      ],
      resources: [
        {
          title: 'pages.appsManager',
          url: '/apps-manager/getting-started',
          icon: 'mdi-apps',
        },
        { title: 'pages.authors', url: '/authors', icon: 'mdi-account-group' },
        { title: 'pages.affiliate', url: '/affiliate', icon: 'mdi-handshake' },
        { title: 'pages.blog', url: '/blog', icon: 'mdi-post-outline' },
        { title: 'pages.faq', url: '/faq', icon: 'mdi-help-circle-outline' },
        {
          title: 'pages.docs',
          url: '/docs',
          icon: 'mdi-text-box-check-outline',
        },
      ],
    };
  },

  methods: {
    toggleDrawer() {
      this.$emit('update:drawer', !this.drawer);
    },
    toHomePage() {
      this.$router.push('/');
    },

    async logout() {
      try {
        await this.$auth.logout();
        this.$toast.success("You've been logged out. See you next time!");
        this.$sentry.configureScope((scope) => scope.setUser(null));
        this.$router.push('/');
      } catch (error) {
        this.$toast.error('Failed to log out. Please try again.');
      }
    },

    setLocale(locale) {
      try {
        // Attempt to set the locale
        this.$auth.$storage.setUniversal('lang', locale);
        this.$i18n.locale = locale;
        this.$vuetify.rtl = this.$i18n.locale === 'ar'; // Set $vuetify.rtl based on the locale
      } catch (error) {
        // Handle the error gracefully
        console.error('Failed to set locale:', error.message);
      }
    },
  },

  computed: {
    ...mapState({
      discount: (state) => state.global.discount,
    }),

    profileImageFullURL() {
      return this.$config.mrBaseUrl + this.$auth.user.profileImageURL;
    },
  },
};
