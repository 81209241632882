/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
// axios.js
export default function ({ $axios, $config, app }, inject) {
  const mrApi = $axios.create({
    baseURL: $config.mrApiBaseUrl,
    withCredentials: false,
  });
  const mrApiV2 = $axios.create({
    baseURL: $config.mrApiBaseUrlV2,
    withCredentials: false,
  });

  const setAuthHeader = (config) => {
    if (app.$auth.loggedIn) {
      config.headers.common.Authorization = app.$auth.strategy.token.get();
    }
  };

  const setLanguageHeader = (config) => {
    // Assuming your URL structure includes '/api/' for relevant calls
    if (config.url.includes('/api/')) {
      config.headers.common['Accept-Language'] = app.i18n.locale;
    }
  };

  // Apply both auth and language header interceptors to both instances
  [mrApi, mrApiV2].forEach((api) => {
    api.onRequest((config) => {
      setAuthHeader(config);
      setLanguageHeader(config);
      return config;
    });

    api.onError(async (error) => {
      // Check if error.response exists before accessing status or data
      const statusCode = error.response ? error.response.status : null;
      const errorMessage =
        error.response && error.response.data
          ? error.response.data.error.message
          : '';

      if (
        statusCode === 403 &&
        app.$auth.loggedIn &&
        errorMessage.includes('Your session has expired')
      ) {
        try {
          // Attempt to refresh tokens if a 403 error is encountered
          await app.$auth.refreshTokens();

          // Retry the original request with the new token
          error.config.headers.Authorization = app.$auth.strategy.token.get();
          // Optionally retry the original request if needed (add code here)
          return api.request(error.config);
        } catch (refreshError) {
          // If token refresh fails, log the user out and redirect
          await app.$auth.logout();
          await app.router.push('/login');
        }
      }

      return Promise.reject(error); // Continue rejecting the error
    });
  });

  inject('mrApi', mrApi);
  inject('mrApiV2', mrApiV2);
}
