import posthog from 'posthog-js';
import Vue from 'vue';

export default function ({ app: { router, store, $cookiz }, route }, inject) {
  // Function to extract UTM parameters from the URL
  const getUtmParams = () => {
    const params = new URLSearchParams(window.location.search);
    return {
      utm_source: params.get('utm_source'),
      utm_medium: params.get('utm_medium'),
      utm_campaign: params.get('utm_campaign'),
      utm_term: params.get('utm_term'),
      utm_content: params.get('utm_content'),
    };
  };

  // Store UTM parameters in cookies
  const storeUtmInCookies = (utmParams) => {
    $cookiz.set('__MOTIONROUTES_UTM', JSON.stringify(utmParams), {
      path: '/',
      maxAge: 60 * 60 * 24 * 30, // 30 days
    });
  };

  // Retrieve UTM parameters from cookies
  const retrieveUtmFromCookies = () => {
    const utmCookie = $cookiz.get('__MOTIONROUTES_UTM');
    return utmCookie && Object.keys(utmCookie).length > 0 ? utmCookie : {};
  };

  // Init PostHog
  posthog.init('phc_fLXmUy8OfedG05FstrQQ45Jgu59WREioc1eVt69q9zt', {
    api_host: 'https://us.i.posthog.com',
    capture_pageview: false,
    loaded: () => {
      const currentUtmParams = getUtmParams();

      // Store UTM parameters in cookies
      if (Object.keys(currentUtmParams).some((key) => currentUtmParams[key])) {
        storeUtmInCookies(currentUtmParams);
      }

      // Retrieve UTM parameters from cookies
      const utmParams = retrieveUtmFromCookies();

      if (store.state.auth.loggedIn) {
        // If the user is logged in, identify them with their username and UTM parameters
        posthog.identify(store.state.auth.user?.userName, {
          $email: store.state.auth.user?.email,
          $name: store.state.auth.user?.fullName,
          $username: store.state.auth.user?.roles,
          ...utmParams, // Add UTM parameters
        });
      } else {
        const distinctId = posthog.get_distinct_id();
        // Check if the current distinct ID is 'unique_id' (mistake)
        if (distinctId === 'unique_id') {
          posthog.reset(); // Resets the session and generates a new anonymous ID
        }

        // Identify with the distinct ID (anonymous user or new distinct ID after reset) and UTM parameters
        const newDistinctId = posthog.get_distinct_id();
        posthog.identify(newDistinctId, {
          ...utmParams, // Add UTM parameters
        });
      }
    },
  });

  // Inject PostHog into the application and make it available via this.$posthog (or app.$posthog)
  inject('posthog', posthog);

  // Make sure that pageviews are captured with each route change
  router.afterEach((to) => {
    Vue.nextTick(() => {
      const utmParams = retrieveUtmFromCookies();
      // Capture pageview with the current route path and UTM parameters
      posthog.capture('$pageview', {
        $current_url: to.fullPath,
        ...utmParams, // Include UTM parameters
      });
    });
  });
}
